<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Explore our climate change resources and links in the footer. 
  Your source for valuable information and actions to combat the climate crisis. Join our mission for a sustainable future.">
  <title>Footer</title>
</head>
<body>
  <section id="footer">
    <div class="footer-container">
      <div class="social-icons">
        <div class="follow">
          <h5>FOLLOW US</h5>
        </div>
        <a href="https://www.facebook.com/profile.php?id=61552375197190" aria-label="Follow us on facebook"><i class='bx bxl-facebook-circle' ></i></a>
        <a href="https://twitter.com/Ecotopia711033" aria-label="Follow us on twitter"><i class='bx bxl-twitter' ></i></a>
        <a href="https://www.instagram.com/ecotopia231/" aria-label="Follow us on instagram"><i class='bx bxl-instagram-alt' ></i></a>
        <a href="https://account.microsoft.com/profile/" aria-label="Email us"><i class='bx bxs-envelope'></i></a>
      </div>
  
      <div class="footer-nav">
        <ul>
          <li><a href="">Home</a></li>
          <li><a href="material">Materials</a></li>
          <li><a href="newsFeatures">News</a></li>
          <li><a href="games">Game</a></li>
          <li><a href="aboutus">About</a></li>
          <li><a href="privacypolicy">Privacy Policy</a></li>
        </ul>
      </div>
  
      <div class="footer-bottom">
        <p>Copyright &copy; 2023 MAAFIA - All Right Reserved</p>
      </div>
    </div>
  </section>
</body>
</html>