<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Test your knowledge and impact on climate change. 
  Take our engaging climate assessment quiz to discover your environmental 
  footprint and learn how you can make a difference today.">
  <title>Document</title>
</head>
  <body>
    <div class="assessment">
      <h1>Self-Assessment</h1>
    </div>
  
    <div class="assessment2">
      <div
        class="container2"
        style="border-color: #501118"
        *ngFor="let item of assessment; let index = index"
      >
        <form>
          <!-- <h2>Pop Quiz</h2> -->
          <ol type="1">
            <li *ngFor="let quiz of item.pop_quiz; let i = index">
              <p
                [ngClass]="{
                  'wrong-answer':
                    submittedPopQuiz &&
                    selectedAnswersPopQuiz[index * 10 + i] !== quiz.correct_answer.text
                }"
              >
                {{ quiz.question }}
              </p>
              <!-- <p>{{ quiz.question }}</p> -->
              <!-- OLD -->
              <!-- <ng-container *ngFor="let option of quiz.options; let j = index">
                <input
                  type="radio"
                  [name]="'p' + (index + i + 1)"
                  [value]="'p' + (index + i + 1) + '_' + (j + 1)"
                  [(ngModel)]="selectedAnswersPopQuiz[index * 10 + i]"
                />
                {{ option }}<br />
                <p>value: {{quiz.correct_answer.text}}</p>
              </ng-container> -->

              <ng-container *ngFor="let option of quiz.options; let j = index">
                <input
                  type="radio"
                  [name]="'p' + (index + i + 1)"
                  [value]=option
                  [(ngModel)]="selectedAnswersPopQuiz[index * 10 + i]"
                />
                <!-- {{ option }}<br /> -->
                {{option}}<br>
              </ng-container>
              <p
                *ngIf="
                  submittedPopQuiz &&
                  selectedAnswersPopQuiz[index * 10 + i] !== quiz.correct_answer.text
                "
                class="correct-answer"
              >
                Correct Answer: {{ quiz.correct_answer.text }}
              </p>
              <p
              *ngIf="
                showUnansweredQuestionMessagePop &&
                !selectedAnswersPopQuiz[index * 10 + i]
              "
              class="validation-error"
            >
              This is a required question
            </p>
            </li>
          </ol>
          <button
            type="submit"
            (click)="submitPopQuiz()"
            style="background-color: #7ae385"
            *ngIf="showSubmitButtonPop"
          >
            Submit
          </button>
          <button
            type="button"
            (click)="resetSelectedAnswersPop()"
            style="background-color: #FF0000; color: white"
            *ngIf="!showPlayAgainButtonPop"
          >
            Reset
          </button>
          <!-- Play Again Button -->
          <button
            type="button"
            (click)="playAgainPop()"
            style="background-color: #007bff; color: white"
            *ngIf="showPlayAgainButtonPop"
          >
            Play Again
          </button>
        </form>
      </div>
    </div>
  
    <div class="score-box" *ngIf="showScoreBox2">
      <div class="score-content">
        <h3>Your Score</h3>
        <p class="score">{{ scorePopQuiz }}</p>
        <button class="close-button" (click)="closeScoreBox2()">Review the form</button>
      </div>
  
      <div *ngIf="showAnswers2" class="answers-section">
        <h3>My Answers</h3>
        <ul>
          <li *ngFor="let answer of selectedAnswersPopQuiz; let i = index">
            Question {{ i + 1 }}: {{ answer }}
            <span *ngIf="answer === correctAnswersPopQuiz[i]" class="correct-answer"> (Correct)</span>
            <span *ngIf="answer !== correctAnswersPopQuiz[i]" class="wrong-answer"> (Incorrect)</span>
          </li>
        </ul>
      </div>
    </div>
  </body>
  
  <app-footer></app-footer>
</html>