<main>
  <app-admin-panel></app-admin-panel>
  <div class="content">
    <div class="textbox" *ngFor="let item of causes_climate_change">
      <div *ngIf="!editing_header" (click)="startEditingHeader()">
        <h2>{{ header }}</h2>
      </div>
      <h3>
        <textarea
          rows="2" cols="25"
          style="color: #021CD3; font-size: 3rem; font-weight: 600"
          *ngIf="editing_header"
          (blur)="finishEditingHeader($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].header"
        ></textarea>
      </h3>
      
        <div *ngIf="!editing_header_desc"
              (click)="startEditingHeaderDescription()" > 
              <p>{{ header_desc }}</p>
        </div>
      <p>
        <textarea
          rows="2" cols="54"
          *ngIf="editing_header_desc"
          (blur)="finishEditingHeaderDescription($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].header_description"
        ></textarea>
      </p>
    </div>
    <div>
      <img
      src="/assets/casesimages/image_case3.webp"
      alt="sad earth"
    />
    <div class="file-upload">
      
      <h6>Format should be .webp</h6>
      <h6>Max image size is 100kb</h6>
      <h6>Resolution should be exact 1200px*1200px</h6>
      <form (submit)="onImageCase3Selected($event)" enctype="multipart/form-data">
        <input type="file" (change)="imageCase3Selected($event)" #image3Selected>
        <button type="submit">Upload</button>
      </form>
    </div>
    </div>
    
  </div>

  <div class="text" *ngFor="let item of causes_climate_change">
    <span id="next">
      <h1 style="color: #001dd3">
        {{ item.cases.case3[0] }}
      </h1>
    </span>
    <div *ngIf="!editing_case_content[0]" (click)="startEditingCaseContent1()">
      <h1
        class="learn-more-button"
        style="text-decoration: none; color: #a2c3f9"
      >
        {{ case3_content[0] }}
      </h1>
    </div>

    <textarea
      style="
        font-size: 2.5rem;
        text-decoration: none;
        color: #a2c3f9;
        font-weight: bold;
      "
      *ngIf="editing_case_content[0]"
      (blur)="finishEditingCaseContent1($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[0]"
    ></textarea>
    
    <div *ngIf="!editing_case_content[1]" (click)="startEditingCaseContent2()">
      <p>
        {{ case3_content[1] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[1]"
      (blur)="finishEditingCaseContent2($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[1]"
    ></textarea>

    <ul>
      <li>
        <div *ngIf="!editing_case_content[2]" (click)="startEditingCaseContent3()">
          <p>
            {{ case3_content[2] }}
        </p>
        </div>
    
        <textarea
          style="font-size: 1.3rem;"
          *ngIf="editing_case_content[2]"
          (blur)="finishEditingCaseContent3($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].case3_content[2]"
        ></textarea>
        <ul>
          <li>
            <div *ngIf="!editing_case_content[3]" (click)="startEditingCaseContent4()">
              <p>
                {{ case3_content[3] }}
            </p>
            </div>
        
            <textarea
              style="font-size: 1.3rem;"
              *ngIf="editing_case_content[3]"
              (blur)="finishEditingCaseContent4($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="causes_climate_change[0].case3_content[3]"
            ></textarea>
          </li>
        </ul>
      </li>
      <li>
        <div *ngIf="!editing_case_content[4]" (click)="startEditingCaseContent5()">
          <p>
            {{ case3_content[4] }}
        </p>
        </div>
    
        <textarea
          style="font-size: 1.3rem;"
          *ngIf="editing_case_content[4]"
          (blur)="finishEditingCaseContent5($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].case3_content[4]"
        ></textarea>
        <ul>
          <li>
            <div *ngIf="!editing_case_content[5]" (click)="startEditingCaseContent6()">
              <p>
                {{ case3_content[5] }}
            </p>
            </div>
        
            <textarea
              style="font-size: 1.3rem;"
              *ngIf="editing_case_content[5]"
              (blur)="finishEditingCaseContent6($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="causes_climate_change[0].case3_content[5]"
            ></textarea>
          </li>
        </ul>
      </li>
      <li>
        <div *ngIf="!editing_case_content[6]" (click)="startEditingCaseContent7()">
          <p>
            {{ case3_content[6] }}
        </p>
        </div>
    
        <textarea
          style="font-size: 1.3rem;"
          *ngIf="editing_case_content[6]"
          (blur)="finishEditingCaseContent7($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].case3_content[6]"
        ></textarea>
      </li>
      <ul>
        <li>
        <div *ngIf="!editing_case_content[7]" (click)="startEditingCaseContent8()">
          <p>
            {{ case3_content[7] }}
        </p>
        </div>
    
        <textarea
          style="font-size: 1.3rem;"
          *ngIf="editing_case_content[7]"
          (blur)="finishEditingCaseContent8($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].case3_content[7]"
        ></textarea>
        </li>
      </ul>
      <li>
        <div *ngIf="!editing_case_content[8]" (click)="startEditingCaseContent9()">
          <p>
            {{ case3_content[8] }}
        </p>
        </div>
    
        <textarea
          style="font-size: 1.3rem;"
          *ngIf="editing_case_content[8]"
          (blur)="finishEditingCaseContent9($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="causes_climate_change[0].case3_content[8]"
        ></textarea>
      </li>
      <ul>
        <li>
          <div *ngIf="!editing_case_content[9]" (click)="startEditingCaseContent10()">
            <p>
              {{ case3_content[9] }}
          </p>
          </div>
      
          <textarea
            style="font-size: 1.3rem;"
            *ngIf="editing_case_content[9]"
            (blur)="finishEditingCaseContent10($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="causes_climate_change[0].case3_content[9]"
          ></textarea>
      </li>
    </ul>
    </ul>
    <br>
    <div *ngIf="!editing_case_content[10]" (click)="startEditingCaseContent11()">
      <h1
        class="learn-more-button"
        style="text-decoration: none; color: #a2c3f9"
      >
        {{ case3_content[10] }}
      </h1>
    </div>

    <textarea
      style="
        font-size: 2.5rem;
        text-decoration: none;
        color: #a2c3f9;
        font-weight: bold;
      "
      *ngIf="editing_case_content[10]"
      (blur)="finishEditingCaseContent11($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[10]"
    ></textarea>
    
    <div *ngIf="!editing_case_content[11]" (click)="startEditingCaseContent12()">
      <p>
        {{ case3_content[11] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[11]"
      (blur)="finishEditingCaseContent12($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[11]"
    ></textarea>

    <br>
    
    <div *ngIf="!editing_case_content[12]" (click)="startEditingCaseContent13()">
      <p>
        {{ case3_content[12] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[12]"
      (blur)="finishEditingCaseContent13($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[12]"
    ></textarea>

    <br>

    <div *ngIf="!editing_case_content[13]" (click)="startEditingCaseContent14()">
      <p>
        {{ case3_content[13] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[13]"
      (blur)="finishEditingCaseContent14($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[13]"
    ></textarea>

    <br>
    
    <div *ngIf="!editing_case_content[14]" (click)="startEditingCaseContent15()">
      <p>
        {{ case3_content[14] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[14]"
      (blur)="finishEditingCaseContent15($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[14]"
    ></textarea>

    <br>
    
    <div *ngIf="!editing_case_content[15]" (click)="startEditingCaseContent16()">
      <p>
        {{ case3_content[15] }}
    </p>
    </div>

    <textarea
      style="font-size: 1.3rem;"
      *ngIf="editing_case_content[15]"
      (blur)="finishEditingCaseContent16($event)"
      class="resizable-input"
      (ngModelChange)="doesChange()"
      [(ngModel)]="causes_climate_change[0].case3_content[15]"
    ></textarea>
  </div>
  <section>
    <div
      class="reference-container"
      *ngFor="let item of causes_climate_change"
      style="margin: 50px"
    >
      <accordion [isAnimated]="true">
        <accordion-group heading="References">
          <div *ngIf="!editing_reference[0]" (click)="startEditingRef1()">
            <p>{{ references[0] }}</p>
          </div>

          <textarea
            rows="1"
            cols="189"
            *ngIf="editing_reference[0]"
            (blur)="finishEditingRef1($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="causes_climate_change[0].references[0]"
          ></textarea>

          <div *ngIf="!editing_reference[1]" (click)="startEditingRef2()">
            <p>{{ references[1] }}</p>
          </div>

          <textarea
            rows="1"
            cols="189"
            *ngIf="editing_reference[1]"
            (blur)="finishEditingRef2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="causes_climate_change[0].references[1]"
          ></textarea>
        </accordion-group>
      </accordion>
    </div>
  </section>
  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</main>
