<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="description" content="Take action against climate change today. Join our mission for a sustainable future. 
    Explore solutions, get involved, and make a difference. Together, we can combat climate change." />
  </head>
  <!-- <div class="parallax-slider">
    <div class="slide-container" [ngStyle]="getSlideStyle()">
      <div
        class="slide"
        [style.backgroundImage]="'url(' + images[currentIndex] + ')'"
      ></div>
      <div class="description-overlay">
        <div class="description">{{ descriptions[currentIndex] }}</div>
      </div>
    </div>
    <button class="arrow left" (click)="prevSlide()">&#9665;</button>
    <button class="arrow right" (click)="nextSlide()">&#9655;</button>
  </div> -->

  <carousel [isAnimated]="true">
    <slide>
      <img src="assets/carousel/1.webp" alt="First slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> -->
      </div>
    </slide>
    <slide>
      <img src="assets/carousel/2.webp" alt="Second slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h3>Second slide label</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
      </div>
    </slide>
    <slide>
      <img src="assets/carousel/3.webp" alt="Third slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> -->
      </div>
    </slide>
    <slide>
      <img src="assets/carousel/4.webp" alt="Fourth slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> -->
      </div>
    </slide>
    <slide>
      <img src="assets/carousel/5.webp" alt="Fifth slide" style="display: block; width: 100%;">
      <div class="carousel-caption d-none d-md-block">
        <!-- <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> -->
      </div>
    </slide>
  </carousel>

  <body>
    <section>
      <div class="box">
        <h2 class="scrolling-title">Typhoon and Extreme Weather Events</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="
              'url(assets/landingpagenew/extremeweather.webp)'
            "
            role="img"
            aria-label="Rescuers pull a rubber boat carrying residents through a flooded street after Typhoon Vamco hit in Marikina City, suburban Manila on November 12, 2020."
          ></div>
          <div class="content">
            <p>
              Rescuers pull a rubber boat carrying residents through a flooded
              street after Typhoon Vamco hit in Marikina City, suburban Manila
              on November 12, 2020.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title2">Sea Level Rise and Coastal Erosion</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="'url(assets/landingpagenew/sealevels.webp)'"
            role="img"
            aria-label="Houses on bamboo stilts amid encroaching bay waters in Sitio Pariahan, Bulacan, as seen in this photo taken on Jan. 11, 2019."
          ></div>
          <div class="content">
            <p>
              This photo taken on Jan. 11, 2019, shows houses on bamboo stilts
              amid encroaching bay waters in Sitio Pariahan, Bulacan.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title">Ocean Acidification</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="
              'url(assets/landingpagenew/oceanacidification.webp)'
            "
            role="img"
            aria-label="Carbon emission speeding up ocean acidification, scientists warn. by Philippine Environews"
          ></div>
          <div class="content">
            <p>
              Carbon emission speeding up ocean acidification, scientists warn.
              by Philippine Environews
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title2">Biodiversity and Ecosystem Loss</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="
              'url(assets/landingpagenew/bioandloss.webp)'
            "
            role="img"
            aria-label="Unsustainable human practices cause excessive loss of natural life, leading to looming threats of species decline and loss. (FPE)"
            alt=""
          ></div>
          <div class="content">
            <p>
              Unsustainable human practices cause excessive loss of natural
              life, leading to looming threats of species decline and loss.
              (FPE)
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title">Agricultural and Food Security</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="
              'url(assets/landingpagenew/agriandfood.webp)'
            "
            role="img"
            aria-label="A Camarines Norte resident checks for what's left of his coconuts after Typhoon Ulysses (Vamco) plowed through his farm. PHOTO BY ROB REYES/RAPPLER"
          ></div>
          <div class="content">
            <p>
              A Camarines Norte resident checks for what's left of his coconuts
              after Typhoon Ulysses (Vamco) plowed through his farm. PHOTO BY
              ROB REYES/RAPPLER
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title2">Health Risk</h2>
        <div class="container">
          <div
            class="imgBx"
            [style.backgroundImage]="
              'url(assets/landingpagenew/healthrisk.webp)'
            "
            role="img"
            aria-label="Children in the Philippines receive treatment for diarrhea, a major health risk that often arises in floodwater after extreme weather. Researchers warn that young people will face more risks like this as the climate changes. Ted Aljibe/AFP/Getty"
          ></div>
          <div class="content">
            <p>
              Children in the Philippines receive treatment for diarrhea, a
              major health risk that often arises in floodwater after extreme
              weather. Researchers warn that young people will face more risks
              like this as the climate changes. Ted Aljibe/AFP/Getty
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box">
        <h2 class="scrolling-title">Water Scarcity</h2>
        <div class="container">
          <img
            src="assets/landingpagenew/waterscarcity.webp"
            alt="Residents line up for water rationed by Manila Water in Barangay Highway Hills, Mandaluyong City on March 11, 2019. The water distributor has limited the supply of water, believed to last through the summer season, in parts of Metro Manila due to increased demand, lack of rains, and low water level at the La Mesa Dam, its emergency source. George Calvelo, ABS-CBN News"
            class="imgBx"
          />
          <div class="content">
            <p>
              Residents line up for water rationed by Manila Water in Barangay
              Highway Hills, Mandaluyong City on March 11, 2019. The water
              distributor has limited the supply of water, believed to last
              through the summer season, in parts of Metro Manila due to
              increased demand, lack of rains, and low water level at the La
              Mesa Dam, its emergency source. George Calvelo, ABS-CBN News
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="parallax-container">
      <div class="parallax-description">
        <h1>
          Eco-Friendly Waste Disposal Practices: A Key Strategy for Climate
          Change Mitigation
        </h1>
        <p>
          Proper waste disposal is crucial for mitigating climate change as it
          reduces the release of harmful greenhouse gases into the environment.
          As an example, not shredding paper and compressing bottles before
          disposal can make recycling and waste management more efficient,
          decreasing the carbon footprint associated with waste processing and
          helping to combat climate change.
        </p>
      </div>
      <div
        class="parallax-image"
        [style.transform]="'scale(' + scale + ')'"
        [style.background-image]="'url(' + imageUrl + ')'"
        (window:scroll)="onScroll($event)"
      ></div>
    </div> -->
  </body>
</html>
