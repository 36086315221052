import { Component } from '@angular/core';

@Component({
  selector: 'app-webtoon',
  templateUrl: './webtoon.component.html',
  styleUrls: ['./webtoon.component.css']
})
export class WebtoonComponent {

}
