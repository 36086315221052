<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
      rel="stylesheet"
    />
    <title></title>
  </head>
  <body>
    <div class="background" style="margin-bottom: 50px">
      <img
        src="assets/solutionsimages/city.webp"
        alt="Act now! image"
        width="100%"
      />
    </div>

    <main>
      <div class="text">
        <h1>
          Data and Information
        </h1>
        <h4 style="color: #000">
          The following resources from government organizations in the United
          States offer information on potential approaches for addressing
          climate change.
        </h4>
      </div>
      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://designingresilience.ph/climate-change-commission/#:~:text=Updates%20Events%20%26%20Activities-,About,Change%20Act%20(RA%209729).">
          <img
            src="/assets/governmentimages/CCC.webp"
            alt="Climate Change Commission (CCC) Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>Climate Change Commission (CCC)</h2>
          <p>
            CCC is the sole policy-making body of the government tasked to
            coordinate, monitor and evaluate the programs and action plans of
            the government relating to climate change pursuant to the provisions
            of the Philippine Climate Change Act (RA 9729).
          </p>
        </div>
      </div>

      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://www.denr.gov.ph/">
          <img
            src="/assets/governmentimages/DENR.webp"
            alt="Department of Environmental and Natural Resources (DENR) Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>Department of Environmental and Natural Resources (DENR)</h2>
          <p>
            The Department of Environment and Natural Resources (DENR) is
            responsible for the conservation, management, and development of the
            country's environment and natural resources. It shall ensure the
            proper use of of these resources and the protection of the
            environment within the framework of sustainable development.
          </p>
        </div>
      </div>

      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://niccdies.climate.gov.ph/">
          <img
            src="/assets/governmentimages/NICCDIES.webp"
            alt="NICCDIES Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>National Integrated Climate Change Database Information and Exchange System (NICCDIES)</h2>
          <p>
            To track the actions necessary to address these climate change
            issues, the National Integrated Climate Change Database Information
            and Exchange System (NICCDIES) serves as the integrated climate
            information portal of the Climate Change Commission. NICCDIES serves
            as the primary enabling platform of the CCC in consolidating and
            monitoring, among other things, data and information on climate
            change and climate action from sources and actors coming from both
            public and private sector and other stakeholders, allowing for
            decision-makers to access, distribute and exchange these data for
            use in policymaking, development planning, investment decision
            making.
          </p>
        </div>
      </div>

      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://www.pagasa.dost.gov.ph/information/climate-change-in-the-philippines">
          <img
            src="/assets/governmentimages/DOST.webp"
            alt="Department of Science and Technology (DOST) Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>Department of Science and Technology (DOST)</h2>
          <p>
            The Department of Science and Technology, is the executive
            department of the Philippine government responsible for the
            coordination of science and technology-related projects in the
            Philippines and to formulate policies and projects in the fields of
            science and technology in support of national development.
          </p>
        </div>
      </div>

      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://www.pna.gov.ph/articles/1164164">
          <img
            src="/assets/governmentimages/PNA.webp"
            alt="Philippine News Agency Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>Philippine News Agency (PNA)</h2>
          <p>
            Philippine News Agency is the official news agency of the Philippine
            government. PNA is under the supervision and control of the News and
            Information Bureau, an attached agency of the Presidential
            Communications Operations Office.
          </p>
        </div>
      </div>

      <div class="container2" data-aos="fade-up" data-aos-duration="1000">
        <div class="image2">
          <a href="https://www.deped.gov.ph/climate-change-education/cce-in-the-philippines/">
          <img
            src="/assets/governmentimages/DePED.webp"
            alt="Department of Education (DEPED) Logo"
            class="enlarge-on-hover"
          />
        </a>
        </div>
        <div class="text2">
          <h2>Department of Education (DEPED)</h2>
          <p>
            The Department of Education is the executive department of the
            Philippine government responsible for ensuring access to, promoting
            equity in, and improving the quality of basic education. It is the
            main agency tasked to manage and govern the Philippine system of
            basic education.
          </p>
        </div>
      </div>

      <section>
        <div
          class="reference-container"
          style="margin: 35px;"
        >
          <accordion [isAnimated]="true">
            <accordion-group heading="References">
              <a
                href="https://designingresilience.ph/climate-change-commission/#:~:text=Updates%20Events%20%26%20Activities-,About,Change%20Act%20(RA%209729)."
                target="_blank"
                >https://designingresilience.ph/climate-change-commission/#:~:text=Updates%20Events%20%26%20Activities-,About,Change%20Act%20(RA%209729).</a>
                <br>
                <a
                href="https://climatechange.denr.gov.ph/"
                target="_blank"
                >https://www.denr.gov.ph/</a>
                <br>
                <a
                href="https://niccdies.climate.gov.ph/"
                target="_blank"
                >https://niccdies.climate.gov.ph/</a>
                <br>
                <a
                href="https://www.pagasa.dost.gov.ph/information/climate-change-in-the-philippines"
                target="_blank"
                >https://www.pagasa.dost.gov.ph/information/climate-change-in-the-philippines</a>
                <br>
                <a
                href="https://www.pna.gov.ph/articles/1164164"
                target="_blank"
                >https://www.pna.gov.ph/articles/1164164</a>
                <br>
                <a
                href="https://www.deped.gov.ph/climate-change-education/cce-in-the-philippines/"
                target="_blank"
                >https://www.deped.gov.ph/climate-change-education/cce-in-the-philippines/</a>
                <br>
            </accordion-group>
          </accordion>
        </div>
      </section>
    </main>
    <footer>
      <app-footer></app-footer>
    </footer>
  </body>
</html>
