<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Explore our Climate Change Website: Navigate our comprehensive resources, articles, 
    and tools for a sustainable future. Find information on climate action, solutions, and awareness." />
  </head>
  <body>
    <header *ngIf="!hideNavbar">
      <input type="checkbox" id="nav_check" hidden>
      <nav>
        <ul>
          <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}"><a routerLink="">Home</a></li>
          <li [routerLinkActive]="'active'"><a routerLink="material">Materials</a>
            <ul class="dropdown">
              <div class="dropdown-content">
                <a
                  (click)="closeNavbar()"
                  [routerLinkActive]="'active'"
                  routerLink="currentIssue"
                  >Current Issues</a
                >
                <a
                  (click)="closeNavbar()"
                  [routerLinkActive]="'active'"
                  routerLink="problems"
                  >Climate Action SDG13</a
                >
                <a
                  (click)="closeNavbar()"
                  [routerLinkActive]="'active'"
                  routerLink="solutions"
                  >Solutions</a
                >
                <a
                  (click)="closeNavbar()"
                  [routerLinkActive]="'active'"
                  routerLink="overviewOfClimateChange"
                  >Overview of Climate Change</a
                >
                <a
                  (click)="closeNavbar()"
                  [routerLinkActive]="'active'"
                  routerLink="assessment"
                  >Self-Assessment</a
                >
              </div>
            </ul>
          </li>
          <li [routerLinkActive]="'active'"><a routerLink="newsFeatures">News</a></li>
          <div class="logo2"><a href=""><img src="/assets/logo/logo.webp" alt="Ecotopia Logo" height="80px" width="120px"/></a></div>
          
          <li [routerLinkActive]="'active'"><a routerLink="games">Games</a></li>
          
          <li [routerLinkActive]="'active'"><a routerLink="aboutus">About Us</a></li>
          
        </ul>
      </nav>
      <div class="logo"><a href=""><img src="/assets/logo/logo.webp" alt="Ecotopia Logo" height="70px" width="100px"/></a></div>
      <label for = "nav_check" class="lines" (click)="toggleMenu()">
        <i class="bx" [ngClass]="{ 'bx-menu': !isMenuOpen, 'bx-x': isMenuOpen }"></i>
      </label>
    </header>
  </body>
</html>
<router-outlet></router-outlet>


