<main>
  <app-admin-panel></app-admin-panel>

  <div class="content">
    <div class="textbox" *ngFor="let item of outdated_engine">
      <div *ngIf="!editing_header" (click)="startEditingHeader()">
        <h2>{{ header }}</h2>
      </div>
      <h3>
        <textarea
          rows="2" cols="25"
          style="color: #021CD3; font-size: 3rem; font-weight: 600"
          *ngIf="editing_header"
          (blur)="finishEditingHeader($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="outdated_engine[0].header"
        ></textarea>
      </h3>
      
        <div *ngIf="!editing_header_desc"
              (click)="startEditingHeaderDescription()" > 
              <p>{{ header_desc }}</p>
        </div>
      <p>
        <textarea
          rows="2" cols="54"
          *ngIf="editing_header_desc"
          (blur)="finishEditingHeaderDescription($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="outdated_engine[0].header_description"
        ></textarea>
      </p>
    </div>
    <div>
      <img
      src="/assets/casesimages/image_case2.webp"
      alt="sad earth"
    />
    <div class="file-upload">
      
      <h6>Format should be .webp</h6>
      <h6>Max image size is 100kb</h6>
      <h6>Resolution should be exact 1200px*1200px</h6>
      <form (submit)="onImageCase2Selected($event)" enctype="multipart/form-data">
        <input type="file" (change)="imageCase2Selected($event)" #image2Selected>
        <button type="submit">Upload</button>
      </form>
    </div>
    </div>
    
  </div>

  <div class="text" *ngFor="let item of outdated_engine">
    <h1 style="padding-top: 50px; color: #a6782a">
      {{ item.cases.case2[0] }}
    </h1>

    <h2 style="color: #a6782a; font-weight: bold; text-align: center">
      SDG Goal 13
    </h2>
    <div class="container">
      <div class="single-column"></div>
      <div class="two-column">
        <div class="left-column" *ngFor="let item of outdated_engine">
          <div *ngIf="!editing_indicator_1[0]" (click)="startEditingIndicator1()">
            <h1
              style="
                font-weight: bold;
              "
            >
              {{ indicator_1[0] }}
            </h1>
          </div>
      
          <textarea
            rows="2" cols="5"
            style="
              font-size: 50px;
              font-weight: bold;
            "
            *ngIf="editing_indicator_1[0]"
            (blur)="finishEditingIndicator1($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_1[0]"
          ></textarea>
        </div>

        <div class="column-space"></div>

        <div class="right-column">
          <div *ngIf="!editing_indicator_1[1]" (click)="startEditingIndicator1_2()">
            <p>{{ indicator_1[1] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-size: 1.3rem;
            "
            *ngIf="editing_indicator_1[1]"
            (blur)="finishEditingIndicator1_2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_1[1]"
          ></textarea>
          <br />
          <div *ngIf="!editing_indicator_1[2]" (click)="startEditingIndicator1_3()">
            <h2>{{ indicator_1[2] }}</h2>
          </div>
      
          <textarea
            rows="2" cols="20"
            style="
              font-size: 1.5rem;
              font-weight: bold;
            "
            *ngIf="editing_indicator_1[2]"
            (blur)="finishEditingIndicator1_3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_1[2]"
          ></textarea>
          <ul>
            <li>
              <div *ngIf="!editing_indicator_1[3]" (click)="startEditingIndicator1_4()">
                <p>{{ indicator_1[3] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_1[3]"
                (blur)="finishEditingIndicator1_4($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_1[3]"
              ></textarea>
            </li>
            <li>
              <div *ngIf="!editing_indicator_1[4]" (click)="startEditingIndicator1_5()">
                <p>{{ indicator_1[4] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_1[4]"
                (blur)="finishEditingIndicator1_5($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_1[4]"
              ></textarea>
            </li>
            <li>
              <div *ngIf="!editing_indicator_1[5]" (click)="startEditingIndicator1_6()">
                <p>{{ indicator_1[5] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_1[5]"
                (blur)="finishEditingIndicator1_6($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_1[5]"
              ></textarea>
            </li>
          </ul>
        </div>
      </div>

      <div class="single-column"></div>
      <div class="two-column">
        <div class="left-column">
          <div *ngIf="!editing_indicator_2[0]" (click)="startEditingIndicator2()">
            <h1
              style="
                font-weight: bold;
              "
            >
              {{ indicator_2[0] }}
            </h1>
          </div>
      
          <textarea
            rows="2" cols="5"
            style="
              font-size: 50px;
              font-weight: bold;
            "
            *ngIf="editing_indicator_2[0]"
            (blur)="finishEditingIndicator2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_2[0]"
          ></textarea>
        </div>

        <div class="column-space"></div>

        <div class="right-column">
          <div *ngIf="!editing_indicator_2[1]" (click)="startEditingIndicator2_2()">
            <p>{{ indicator_2[1] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-size: 1.3rem;
            "
            *ngIf="editing_indicator_2[1]"
            (blur)="finishEditingIndicator2_2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_2[1]"
          ></textarea>
          <br />
          <div *ngIf="!editing_indicator_2[2]" (click)="startEditingIndicator2_3()">
            <h2>{{ indicator_2[2] }}</h2>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-weight: bold;
              font-size: 1.5rem;
            "
            *ngIf="editing_indicator_2[2]"
            (blur)="finishEditingIndicator2_3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_2[2]"
          ></textarea>
          <ul>
            <li>
              <div *ngIf="!editing_indicator_2[3]" (click)="startEditingIndicator2_4()">
                <p>{{ indicator_2[3] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_2[3]"
                (blur)="finishEditingIndicator2_4($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_2[3]"
              ></textarea>
            </li>
            <li>
              <div *ngIf="!editing_indicator_2[4]" (click)="startEditingIndicator2_5()">
                <p>{{ indicator_2[4] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_2[4]"
                (blur)="finishEditingIndicator2_5($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_2[4]"
              ></textarea>
            </li>
          </ul>
        </div>
      </div>

      <div class="single-column"></div>
      <div class="two-column">
        <div class="left-column">
          <div *ngIf="!editing_indicator_3[0]" (click)="startEditingIndicator3()">
            <h1
              style="
                font-weight: bold;
              "
            >
              {{ indicator_3[0] }}
            </h1>
          </div>
      
          <textarea
            rows="2" cols="5"
            style="
              font-size: 50px;
              font-weight: bold;
            "
            *ngIf="editing_indicator_3[0]"
            (blur)="finishEditingIndicator3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_3[0]"
          ></textarea>
        </div>

        <div class="column-space"></div>

        <div class="right-column">
          <div *ngIf="!editing_indicator_3[1]" (click)="startEditingIndicator3_2()">
            <p>{{ indicator_3[1] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-size: 1.3rem;
            "
            *ngIf="editing_indicator_3[1]"
            (blur)="finishEditingIndicator3_2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_3[1]"
          ></textarea>
          <br />
           <div *ngIf="!editing_indicator_3[2]" (click)="startEditingIndicator3_3()">
            <h2>{{ indicator_3[2] }}</h2>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-weight: bold;
              font-size: 1.5rem;
            "
            *ngIf="editing_indicator_3[2]"
            (blur)="finishEditingIndicator3_3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_3[2]"
          ></textarea>
          <ul>
            <li>
              <div *ngIf="!editing_indicator_3[3]" (click)="startEditingIndicator3_4()">
                <p>{{ indicator_3[3] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_3[3]"
                (blur)="finishEditingIndicator3_4($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_3[3]"
              ></textarea>
            </li>
          </ul>
        </div>
      </div>

      <div class="single-column"></div>
      <div class="two-column">
        <div class="left-column">
          <div *ngIf="!editing_indicator_4[0]" (click)="startEditingIndicator4()">
            <h1
              style="
                font-weight: bold;
              "
            >
              {{ indicator_4[0] }}
            </h1>
          </div>
      
          <textarea
            rows="2" cols="5"
            style="
              font-size: 50px;
              font-weight: bold;
            "
            *ngIf="editing_indicator_4[0]"
            (blur)="finishEditingIndicator4($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_4[0]"
          ></textarea>
        </div>

        <div class="column-space"></div>

        <div class="right-column">
          <div *ngIf="!editing_indicator_4[1]" (click)="startEditingIndicator4_2()">
            <p>{{ indicator_4[1] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-size: 1.3rem;
            "
            *ngIf="editing_indicator_4[1]"
            (blur)="finishEditingIndicator4_2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_4[1]"
          ></textarea>
          <br />
          <div *ngIf="!editing_indicator_4[2]" (click)="startEditingIndicator4_3()">
            <p>{{ indicator_4[2] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-weight: bold;
              font-size: 1.5rem;
            "
            *ngIf="editing_indicator_4[2]"
            (blur)="finishEditingIndicator4_3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_4[2]"
          ></textarea>
          <ul>
            <li>
              <div *ngIf="!editing_indicator_4[3]" (click)="startEditingIndicator4_4()">
                <p>{{ indicator_4[3] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_4[3]"
                (blur)="finishEditingIndicator4_4($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_4[3]"
              ></textarea>
            </li>
          </ul>
        </div>
      </div>

      <div class="single-column"></div>
      <div class="two-column">
        <div class="left-column">
          <!-- <START -->
            <div *ngIf="!editing_indicator_5[0]" (click)="startEditingIndicator5()">
              <h1
                style="
                  font-weight: bold;
                "
              >
                {{ indicator_5[0] }}
              </h1>
            </div>
        
            <textarea
              rows="2" cols="5"
              style="
                font-size: 50px;
                font-weight: bold;

              "
              *ngIf="editing_indicator_5[0]"
              (blur)="finishEditingIndicator5($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="outdated_engine[0].indicator_5[0]"
            ></textarea>
            <!-- END -->
        </div>

        <div class="column-space"></div>

        <div class="right-column">
          <div *ngIf="!editing_indicator_5[1]" (click)="startEditingIndicator5_2()">
            <p>{{ indicator_5[1] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-size: 1.3rem;
            "
            *ngIf="editing_indicator_5[1]"
            (blur)="finishEditingIndicator5_2($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_5[1]"
          ></textarea>
          <br />
          <div *ngIf="!editing_indicator_5[2]" (click)="startEditingIndicator5_3()">
            <p>{{ indicator_5[2] }}</p>
          </div>
      
          <textarea
            rows="2" cols="30"
            style="
              font-weight: bold;
              font-size: 1.5rem;
            "
            *ngIf="editing_indicator_5[2]"
            (blur)="finishEditingIndicator5_3($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].indicator_5[2]"
          ></textarea>
          <ul>
            <li>
              <div *ngIf="!editing_indicator_5[3]" (click)="startEditingIndicator5_4()">
                <p>{{ indicator_5[3] }}</p>
              </div>
          
              <textarea
                rows="2" cols="30"
                style="
                  font-size: 1.3rem;
                "
                *ngIf="editing_indicator_5[3]"
                (blur)="finishEditingIndicator5_4($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="outdated_engine[0].indicator_5[3]"
              ></textarea>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <section>
    <div
      class="reference-container"
      *ngFor="let item of outdated_engine"
      style="margin: 50px"
    >
      <accordion [isAnimated]="true">
        <accordion-group heading="References">
          <div *ngIf="!editing_reference[0]" (click)="startEditingRef1()">
            <p>{{ references[0] }}</p>
          </div>

          <textarea
            rows="1"
            cols="189"
            style="font-size: 1.2rem;"
            *ngIf="editing_reference[0]"
            (blur)="finishEditingRef1($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="outdated_engine[0].references[0]"
          ></textarea>
        </accordion-group>
      </accordion>
    </div>
  </section>

  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</main>
