<body>
  <div id="sidebar" [ngClass]="{ collapsed: isCollapsed }">
    <button class="toggle-button" (click)="toggleSidebar()">
      <i *ngIf="isCollapsed" class="bx bx-menu"></i>
      <i *ngIf="!isCollapsed" class="bx bx-horizontal-left"></i>
    </button>
    <div class="admin-panel" style="margin-left: -5px">
        <img src="assets/user.webp" alt="Admin Image" style="background-color: white;"/>
        <i *ngIf="!isCollapsed"><span>{{username}}</span></i>
    </div>
    <hr>
    <ul class="nav-list">
      <ng-container *ngFor="let item of navItems">
        <li *ngIf="!item.children">
          <a *ngIf="!isCollapsed" [routerLink]="[item.route]" routerLinkActive="active">{{ item.text }}</a>
          <a *ngIf="isCollapsed" [routerLink]="[item.route]" routerLinkActive="active" class="collapsed-icon">
            <i class="bx {{ item.icon }}"></i>
          </a>
        </li>
        <li *ngIf="item.children">
          <a (click)="toggleDropdown(item)" style="cursor: pointer;">
            <span *ngIf="!isCollapsed">{{ item.text }}</span>
            <i *ngIf="!isCollapsed" class="bx bx-chevron-down" [ngClass]="{ rotate: item.showChildren }"></i>
            <a *ngIf="isCollapsed" [routerLink]="[item.route]" class="bx {{ item.icon }}" style="margin-left: -15px;"></a>
          </a>
          <ul *ngIf="item.showChildren">
            <li *ngFor="let childItem of item.children" class="dropdown-item">
              <a [routerLink]="[childItem.route]">
                <span *ngIf="!isCollapsed">{{ childItem.text }}</span>
                <i *ngIf="isCollapsed" class="bx {{ childItem.icon }}" style="margin-left: -15px;"></i>
              </a>
            </li>
          </ul>
        </li>

      </ng-container>
      <div id="logout"> 
        <a (click)="logout()">
        <i *ngIf="isCollapsed" class="bx bx-log-out"></i>
        <i *ngIf="!isCollapsed" class="bx bxs-log-out"
          ><span style="font-weight: bold"> Logout</span></i>
        </a>
      </div>
    </ul>
  </div>
</body>

