<body>
  <app-admin-panel></app-admin-panel>
  <section class="climate-change">
    <h1>Current Issues on Climate Change in the Philippines</h1>
    <div class="climate-change-info">
      <div>
        <tabset>
          <tab heading="Typhoon and Extreme Weather Events" id="tab1">
            <div class="ccs-div1">
              <div class="left-column">
              <div class="ccs-sub-div1">
                <div
                  *ngIf="!editing_sea_level_rise_coastal_erosion[0]"
                  (click)="startEditingSeaLevelErosion(0)"
                >
                  <p>{{ sea_level_rise_coastal_erosion[0] }}</p>
                </div>
  
                <textarea
                  style="font-size: 1.3rem"
                  *ngIf="editing_sea_level_rise_coastal_erosion[0]"
                  (blur)="finishEditingSeaLevelErosion(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="
                    current_issue_ph[0].sea_level_rise_coastal_erosion[0]
                  "
                ></textarea>
              </div>
              </div>
              
              <div class="ccs-sub-div2">
                <table class="ccs-table" style="margin-bottom: 30px">
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_sea_level_rise_coastal_erosion[1]"
                        (click)="startEditingSeaLevelErosion(1)"
                      >
                        {{ sea_level_rise_coastal_erosion[1] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_sea_level_rise_coastal_erosion[1]"
                        (blur)="finishEditingSeaLevelErosion(1, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].sea_level_rise_coastal_erosion[1]
                        "
                      ></textarea>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_sea_level_rise_coastal_erosion[2]"
                        (click)="startEditingSeaLevelErosion(2)"
                      >
                        {{ sea_level_rise_coastal_erosion[2] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_sea_level_rise_coastal_erosion[2]"
                        (blur)="finishEditingSeaLevelErosion(2, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].sea_level_rise_coastal_erosion[2]
                        "
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </tab>

          <tab heading="Sea Level Rise and Coastal Erosion">
            <div class="ccs-div1">
              <div class="ccs-sub-div1">
                <div
                  *ngIf="!editing_sea_level_rise_coastal_erosion_2[0]"
                  (click)="startEditingSeaLevelErosion2(0)"
                >
                  <p>{{ sea_level_rise_coastal_erosion_2[0] }}</p>
                </div>
  
                <textarea
                  rows="4" cols="152"
                  style="font-size: 1.3rem"
                  *ngIf="editing_sea_level_rise_coastal_erosion_2[0]"
                  (blur)="finishEditingSeaLevelErosion2(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="
                    current_issue_ph[0].sea_level_rise_coastal_erosion_2[0]
                  "
                ></textarea>
              </div>
  
              <div class="ccs-sub-div2">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_sea_level_rise_coastal_erosion_2[1]"
                        (click)="startEditingSeaLevelErosion2(1)"
                      >
                        {{ sea_level_rise_coastal_erosion_2[1] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_sea_level_rise_coastal_erosion_2[1]"
                        (blur)="finishEditingSeaLevelErosion2(1, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].sea_level_rise_coastal_erosion_2[1]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_sea_level_rise_coastal_erosion_2[2]"
                            (click)="startEditingSeaLevelErosion2(2)"
                          >
                            {{ sea_level_rise_coastal_erosion_2[2] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_sea_level_rise_coastal_erosion_2[2]"
                            (blur)="finishEditingSeaLevelErosion2(2, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0]
                                .sea_level_rise_coastal_erosion_2[2]
                            "
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_sea_level_rise_coastal_erosion_2[3]"
                            (click)="startEditingSeaLevelErosion2(3)"
                          >
                            {{ sea_level_rise_coastal_erosion_2[3] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_sea_level_rise_coastal_erosion_2[3]"
                            (blur)="finishEditingSeaLevelErosion2(3, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0]
                                .sea_level_rise_coastal_erosion_2[3]
                            "
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_sea_level_rise_coastal_erosion_2[4]"
                            (click)="startEditingSeaLevelErosion2(4)"
                          >
                            {{ sea_level_rise_coastal_erosion_2[4] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_sea_level_rise_coastal_erosion_2[4]"
                            (blur)="finishEditingSeaLevelErosion2(4, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0]
                                .sea_level_rise_coastal_erosion_2[4]
                            "
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_sea_level_rise_coastal_erosion_2[5]"
                            (click)="startEditingSeaLevelErosion2(5)"
                          >
                            {{ sea_level_rise_coastal_erosion_2[5] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_sea_level_rise_coastal_erosion_2[5]"
                            (blur)="finishEditingSeaLevelErosion2(5, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0]
                                .sea_level_rise_coastal_erosion_2[5]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <div class="ccs-sub-div2" style="margin-bottom: 30px">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_sea_level_rise_coastal_erosion_2[6]"
                        (click)="startEditingSeaLevelErosion2(6)"
                      >
                        {{ sea_level_rise_coastal_erosion_2[6] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_sea_level_rise_coastal_erosion_2[6]"
                        (blur)="finishEditingSeaLevelErosion2(6, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].sea_level_rise_coastal_erosion_2[6]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_sea_level_rise_coastal_erosion_2[7]"
                            (click)="startEditingSeaLevelErosion2(7)"
                          >
                            {{ sea_level_rise_coastal_erosion_2[7] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_sea_level_rise_coastal_erosion_2[7]"
                            (blur)="finishEditingSeaLevelErosion2(7, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0]
                                .sea_level_rise_coastal_erosion_2[7]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </tab>

          <tab heading="Ocean Acidification">
            <div class="ccs-div1">
              <div class="ccs-sub-div1">
                <div
                *ngIf="!editing_ocean_acidification[0]"
                (click)="startEditingOceanAcidification(0)"
                >
                  <h2 style="font-weight: bold;">{{ ocean_acidification[0] }}</h2>
                </div>

                <textarea
                  rows="2" cols="25"
                  style="font-size: 2rem;
                  font-weight: bold;"
                  *ngIf="editing_ocean_acidification[0]"
                  (blur)="finishEditingOceanAcidification(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="current_issue_ph[0].ocean_acidification[0]"
                ></textarea>
                <br>

                <div
                *ngIf="!editing_ocean_acidification[1]"
                (click)="startEditingOceanAcidification(1)"
                >
                  <p >{{ ocean_acidification[1] }}</p>
                </div>

                <textarea
                  rows="3" cols="50"
                  style="font-size: 1.3rem;"
                  *ngIf="editing_ocean_acidification[1]"
                  (blur)="finishEditingOceanAcidification(1, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="current_issue_ph[0].ocean_acidification[1]"
                ></textarea>
              </div>
  
              <div class="ccs-sub-div2">
                <table class="ccs-table" style="margin-bottom: 25px">
                  <tr>
                    <th>
                      <div
                      *ngIf="!editing_ocean_acidification[2]"
                      (click)="startEditingOceanAcidification(2)"
                      >
                        <p >{{ ocean_acidification[2] }}</p>
                      </div>
      
                      <textarea
                        rows="3" cols="50"
                        style="font-size: 1.3rem; font-weight: bold;"
                        *ngIf="editing_ocean_acidification[2]"
                        (blur)="finishEditingOceanAcidification(2, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="current_issue_ph[0].ocean_acidification[2]"
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[3]"
                          (click)="startEditingOceanAcidification(3)"
                          >
                            <p >{{ ocean_acidification[3] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[3]"
                            (blur)="finishEditingOceanAcidification(3, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[3]"
                          ></textarea>
                        </li>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[4]"
                          (click)="startEditingOceanAcidification(4)"
                          >
                            <p >{{ ocean_acidification[4] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[4]"
                            (blur)="finishEditingOceanAcidification(4, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[4]"
                          ></textarea>
                        </li>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[5]"
                          (click)="startEditingOceanAcidification(5)"
                          >
                            <p >{{ ocean_acidification[5] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[5]"
                            (blur)="finishEditingOceanAcidification(5, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[5]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr></tr>
                </table>
  
                <table class="ccs-table" style="margin-bottom: 25px">
                  <tr>
                    <th>
                      <div
                      *ngIf="!editing_ocean_acidification[6]"
                      (click)="startEditingOceanAcidification(6)"
                      >
                        <p >{{ ocean_acidification[6] }}</p>
                      </div>
      
                      <textarea
                        rows="3" cols="50"
                        style="font-size: 1.3rem; font-weight: bold;"
                        *ngIf="editing_ocean_acidification[6]"
                        (blur)="finishEditingOceanAcidification(6, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="current_issue_ph[0].ocean_acidification[6]"
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[7]"
                          (click)="startEditingOceanAcidification(7)"
                          >
                            <p >{{ ocean_acidification[7] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[7]"
                            (blur)="finishEditingOceanAcidification(7, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[7]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr></tr>
                </table>
  
                <table class="ccs-table" style="margin-bottom: 30px">
                  <tr>
                    <th> <div
                      *ngIf="!editing_ocean_acidification[8]"
                      (click)="startEditingOceanAcidification(8)"
                      >
                        <p >{{ ocean_acidification[8] }}</p>
                      </div>
      
                      <textarea
                        rows="3" cols="50"
                        style="font-size: 1.3rem; font-weight: bold"
                        *ngIf="editing_ocean_acidification[8]"
                        (blur)="finishEditingOceanAcidification(8, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="current_issue_ph[0].ocean_acidification[8]"
                      ></textarea></th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[9]"
                          (click)="startEditingOceanAcidification(9)"
                          >
                            <p >{{ ocean_acidification[9] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[9]"
                            (blur)="finishEditingOceanAcidification(9, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[9]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                          *ngIf="!editing_ocean_acidification[10]"
                          (click)="startEditingOceanAcidification(10)"
                          >
                            <p >{{ ocean_acidification[10] }}</p>
                          </div>
          
                          <textarea
                            rows="3" cols="50"
                            style="font-size: 1.3rem;"
                            *ngIf="editing_ocean_acidification[10]"
                            (blur)="finishEditingOceanAcidification(10, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].ocean_acidification[10]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
                
              </div>
            </div>
          </tab>

          <tab heading="Biodiversity and Ecosystem Loss">
            <div class="ccs-div1">
              <div class="ccs-sub-div1">
                <div
                  *ngIf="!editing_biodiversity_ecosystem_loss[0]"
                  (click)="startEditingBiodiversityEcosystemLoss(0)"
                >
                  <p>{{ biodiversity_ecosystem_loss[0] }}</p>
                </div>
  
                <textarea
                  rows="4" cols="152"
                  style="font-size: 1.3rem"
                  *ngIf="editing_biodiversity_ecosystem_loss[0]"
                  (blur)="finishEditingBiodiversityEcosystemLoss(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="current_issue_ph[0].biodiversity_ecosystem_loss[0]"
                ></textarea>
              </div>
  
              <div class="ccs-sub-div2">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_biodiversity_ecosystem_loss[1]"
                        (click)="startEditingBiodiversityEcosystemLoss(1)"
                      >
                        {{ biodiversity_ecosystem_loss[1] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_biodiversity_ecosystem_loss[1]"
                        (blur)="finishEditingBiodiversityEcosystemLoss(1, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].biodiversity_ecosystem_loss[1]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_biodiversity_ecosystem_loss[2]"
                            (click)="startEditingBiodiversityEcosystemLoss(2)"
                          >
                            {{ biodiversity_ecosystem_loss[2] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_biodiversity_ecosystem_loss[2]"
                            (blur)="
                              finishEditingBiodiversityEcosystemLoss(2, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].biodiversity_ecosystem_loss[2]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_biodiversity_ecosystem_loss[3]"
                            (click)="startEditingBiodiversityEcosystemLoss(3)"
                          >
                            {{ biodiversity_ecosystem_loss[3] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_biodiversity_ecosystem_loss[3]"
                            (blur)="
                              finishEditingBiodiversityEcosystemLoss(3, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].biodiversity_ecosystem_loss[3]
                            "
                          ></textarea>
                          <ul style="margin-top: 10px">
                            <li>
                              <div
                                *ngIf="!editing_biodiversity_ecosystem_loss[4]"
                                (click)="startEditingBiodiversityEcosystemLoss(4)"
                              >
                                {{ biodiversity_ecosystem_loss[4] }}
                              </div>
  
                              <textarea
                                rows="3" cols="120"
                                style="font-size: 1.3rem"
                                *ngIf="editing_biodiversity_ecosystem_loss[4]"
                                (blur)="
                                  finishEditingBiodiversityEcosystemLoss(
                                    4,
                                    $event
                                  )
                                "
                                class="resizable-input"
                                (ngModelChange)="doesChange()"
                                [(ngModel)]="
                                  current_issue_ph[0]
                                    .biodiversity_ecosystem_loss[4]
                                "
                              ></textarea>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <div class="ccs-sub-div2" style="margin-bottom: 25px">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_biodiversity_ecosystem_loss[5]"
                        (click)="startEditingBiodiversityEcosystemLoss(5)"
                      >
                        {{ biodiversity_ecosystem_loss[5] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_biodiversity_ecosystem_loss[5]"
                        (blur)="finishEditingBiodiversityEcosystemLoss(5, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].biodiversity_ecosystem_loss[5]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_biodiversity_ecosystem_loss[6]"
                        (click)="startEditingBiodiversityEcosystemLoss(6)"
                      >
                        {{ biodiversity_ecosystem_loss[6] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_biodiversity_ecosystem_loss[6]"
                        (blur)="finishEditingBiodiversityEcosystemLoss(6, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].biodiversity_ecosystem_loss[6]
                        "
                      ></textarea>
                    </td> 
                  </tr>
                </table>
              </div>
  
              <div class="ccs-sub-div2" style="margin-bottom: 30px">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_biodiversity_ecosystem_loss[7]"
                        (click)="startEditingBiodiversityEcosystemLoss(7)"
                      >
                        {{ biodiversity_ecosystem_loss[7] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_biodiversity_ecosystem_loss[7]"
                        (blur)="finishEditingBiodiversityEcosystemLoss(7, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].biodiversity_ecosystem_loss[7]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul style="margin-top: 10px">
                        <li>
                          <div
                            *ngIf="!editing_biodiversity_ecosystem_loss[8]"
                            (click)="startEditingBiodiversityEcosystemLoss(8)"
                          >
                            {{ biodiversity_ecosystem_loss[8] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_biodiversity_ecosystem_loss[8]"
                            (blur)="
                              finishEditingBiodiversityEcosystemLoss(8, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].biodiversity_ecosystem_loss[8]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </tab>

          <tab heading="Agriculture and Food Security">
            <div class="ccs-div1">
              <div class="ccs-sub-div1">
                <div
                  *ngIf="!editing_agriculture_food_security[0]"
                  (click)="startEditingAgricultureFoodSecurity(0)"
                >
                  <p>{{ agriculture_food_security[0] }}</p>
                </div>
  
                <textarea
                  rows="4" cols="152"
                  style="font-size: 1.3rem"
                  *ngIf="editing_agriculture_food_security[0]"
                  (blur)="finishEditingAgricultureFoodSecurity(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="current_issue_ph[0].agriculture_food_security[0]"
                ></textarea>
              </div>
  
              <div class="ccs-sub-div2">
                <table class="ccs-table">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_agriculture_food_security[1]"
                        (click)="startEditingAgricultureFoodSecurity(1)"
                      >
                        {{ agriculture_food_security[1] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_agriculture_food_security[1]"
                        (blur)="finishEditingAgricultureFoodSecurity(1, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[1]
                        "
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_agriculture_food_security[2]"
                            (click)="startEditingAgricultureFoodSecurity(2)"
                          >
                            {{ agriculture_food_security[2] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[2]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(2, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[2]
                            "
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_agriculture_food_security[3]"
                            (click)="startEditingAgricultureFoodSecurity(3)"
                          >
                            {{ agriculture_food_security[3] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[3]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(3, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[3]
                            "
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_agriculture_food_security[4]"
                            (click)="startEditingAgricultureFoodSecurity(4)"
                          >
                            {{ agriculture_food_security[4] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[4]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(4, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[4]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li style="list-style-type: lower-roman">
                          <div
                            *ngIf="!editing_agriculture_food_security[5]"
                            (click)="startEditingAgricultureFoodSecurity(5)"
                          >
                            {{ agriculture_food_security[5] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[5]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(5, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[5]
                            "
                          ></textarea>
                          <ul>
                            <li>
                              <div
                                *ngIf="!editing_agriculture_food_security[6]"
                                (click)="startEditingAgricultureFoodSecurity(6)"
                              >
                                {{ agriculture_food_security[6] }}
                              </div>
  
                              <textarea
                                rows="3" cols="120"
                                style="font-size: 1.3rem"
                                *ngIf="editing_agriculture_food_security[6]"
                                (blur)="
                                  finishEditingAgricultureFoodSecurity(6, $event)
                                "
                                class="resizable-input"
                                (ngModelChange)="doesChange()"
                                [(ngModel)]="
                                  current_issue_ph[0].agriculture_food_security[6]
                                "
                              ></textarea>
                            </li>
                          </ul>
                        </li>
                      </ul>
  
                      <ul>
                        <li style="list-style-type: lower-roman">
                          <div
                            *ngIf="!editing_agriculture_food_security[7]"
                            (click)="startEditingAgricultureFoodSecurity(7)"
                          >
                            {{ agriculture_food_security[7] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[7]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(7, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[7]
                            "
                          ></textarea>
                          <ul>
                            <li>
                              <div
                                *ngIf="!editing_agriculture_food_security[8]"
                                (click)="startEditingAgricultureFoodSecurity(8)"
                              >
                                {{ agriculture_food_security[8] }}
                              </div>
  
                              <textarea
                                rows="3" cols="120"
                                style="font-size: 1.3rem"
                                *ngIf="editing_agriculture_food_security[8]"
                                (blur)="
                                  finishEditingAgricultureFoodSecurity(8, $event)
                                "
                                class="resizable-input"
                                (ngModelChange)="doesChange()"
                                [(ngModel)]="
                                  current_issue_ph[0].agriculture_food_security[8]
                                "
                              ></textarea>
                            </li>
                            <li>
                              <div
                                *ngIf="!editing_agriculture_food_security[9]"
                                (click)="startEditingAgricultureFoodSecurity(9)"
                              >
                                {{ agriculture_food_security[9] }}
                              </div>
  
                              <textarea
                                rows="3" cols="120"
                                style="font-size: 1.3rem"
                                *ngIf="editing_agriculture_food_security[9]"
                                (blur)="
                                  finishEditingAgricultureFoodSecurity(9, $event)
                                "
                                class="resizable-input"
                                (ngModelChange)="doesChange()"
                                [(ngModel)]="
                                  current_issue_ph[0].agriculture_food_security[9]
                                "
                              ></textarea>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul style="margin-top: 10px">
                        <li>
                          <div
                            *ngIf="!editing_agriculture_food_security[10]"
                            (click)="startEditingAgricultureFoodSecurity(10)"
                          >
                            {{ agriculture_food_security[10] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_agriculture_food_security[10]"
                            (blur)="
                              finishEditingAgricultureFoodSecurity(10, $event)
                            "
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="
                              current_issue_ph[0].agriculture_food_security[10]
                            "
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <div class="ccs-sub-div2" style="margin-bottom: 25px">
                <table class="ccs-table">
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_agriculture_food_security[11]"
                        (click)="startEditingAgricultureFoodSecurity(11)"
                      >
                        {{ agriculture_food_security[11] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_agriculture_food_security[11]"
                        (blur)="finishEditingAgricultureFoodSecurity(11, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[11]
                        "
                      ></textarea>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_agriculture_food_security[12]"
                        (click)="startEditingAgricultureFoodSecurity(12)"
                      >
                        {{ agriculture_food_security[12] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_agriculture_food_security[12]"
                        (blur)="finishEditingAgricultureFoodSecurity(12, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[12]
                        "
                      ></textarea>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_agriculture_food_security[13]"
                        (click)="startEditingAgricultureFoodSecurity(13)"
                      >
                        {{ agriculture_food_security[13] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_agriculture_food_security[13]"
                        (blur)="finishEditingAgricultureFoodSecurity(13, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[13]
                        "
                      ></textarea>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_agriculture_food_security[14]"
                        (click)="startEditingAgricultureFoodSecurity(14)"
                      >
                        {{ agriculture_food_security[14] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_agriculture_food_security[14]"
                        (blur)="finishEditingAgricultureFoodSecurity(14, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[14]
                        "
                      ></textarea>
                    </td>
                  </tr>
  
                  <tr>
                    <td>
                      <div
                        *ngIf="!editing_agriculture_food_security[15]"
                        (click)="startEditingAgricultureFoodSecurity(15)"
                      >
                        {{ agriculture_food_security[15] }}
                      </div>
  
                      <textarea
                        rows="3" cols="128"
                        style="font-size: 1.3rem"
                        *ngIf="editing_agriculture_food_security[15]"
                        (blur)="finishEditingAgricultureFoodSecurity(15, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="
                          current_issue_ph[0].agriculture_food_security[15]
                        "
                      ></textarea>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </tab>
          
          <tab heading="Health Risk">
            <div class="ccs-div1">
              <div class="ccs-sub-div1">
                <div
                  *ngIf="!editing_health_risk[0]"
                  (click)="startEditingHealthRisk(0)"
                >
                  <p>{{ health_risk[0] }}</p>
                </div>
  
                <textarea
                  rows="4" cols="152"
                  style="font-size: 1.3rem"
                  *ngIf="editing_health_risk[0]"
                  (blur)="finishEditingHealthRisk(0, $event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="current_issue_ph[0].health_risk[0]"
                ></textarea>
              </div>
  
              <div class="ccs-sub-div2">
                <table class="ccs-table" style="margin-bottom: 25px">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_health_risk[1]"
                        (click)="startEditingHealthRisk(1)"
                      >
                        {{ health_risk[1] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_health_risk[1]"
                        (blur)="finishEditingHealthRisk(1, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="current_issue_ph[0].health_risk[1]"
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_health_risk[2]"
                            (click)="startEditingHealthRisk(2)"
                          >
                            {{ health_risk[2] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_health_risk[2]"
                            (blur)="finishEditingHealthRisk(2, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].health_risk[2]"
                          ></textarea>
                        </li>
                        <li>
                          <div
                            *ngIf="!editing_health_risk[3]"
                            (click)="startEditingHealthRisk(3)"
                          >
                            {{ health_risk[3] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_health_risk[3]"
                            (blur)="finishEditingHealthRisk(3, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].health_risk[3]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr></tr>
                </table>
              </div>
              
                <div class="ccs-sub-div2" style="margin-bottom: 25px">
                <table class="ccs-table" style="margin-bottom: 30px">
                  <tr>
                    <th>
                      <div
                        *ngIf="!editing_health_risk[4]"
                        (click)="startEditingHealthRisk(4)"
                      >
                        {{ health_risk[4] }}
                      </div>
  
                      <textarea
                        rows="2" cols="117"
                        style="font-size: 1.3rem; font-weight: 600"
                        *ngIf="editing_health_risk[4]"
                        (blur)="finishEditingHealthRisk(4, $event)"
                        class="resizable-input"
                        (ngModelChange)="doesChange()"
                        [(ngModel)]="current_issue_ph[0].health_risk[4]"
                      ></textarea>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          <div
                            *ngIf="!editing_health_risk[5]"
                            (click)="startEditingHealthRisk(5)"
                          >
                            {{ health_risk[5] }}
                          </div>
  
                          <textarea
                            rows="3" cols="125"
                            style="font-size: 1.3rem"
                            *ngIf="editing_health_risk[5]"
                            (blur)="finishEditingHealthRisk(5, $event)"
                            class="resizable-input"
                            (ngModelChange)="doesChange()"
                            [(ngModel)]="current_issue_ph[0].health_risk[5]"
                          ></textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr></tr>
                </table>
              </div>
            </div>
          </tab>

          <tab heading="Water Scarcity">
            <div class="ccs-div1">
            <div class="ccs-sub-div1">
              <div
                *ngIf="!editing_water_scarcity[0]"
                (click)="startEditingWaterScarcity(0)"
              >
                <p>{{ water_scarcity[0] }}</p>
              </div>

              <textarea
                rows="4" cols="152"
                style="font-size: 1.3rem"
                *ngIf="editing_water_scarcity[0]"
                (blur)="finishEditingWaterScarcity(0, $event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="current_issue_ph[0].water_scarcity[0]"
              ></textarea>
            </div>

            <div class="ccs-sub-div2">
              <table class="ccs-table" style="margin-bottom: 25px">
                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[1]"
                      (click)="startEditingWaterScarcity(1)"
                    >
                      {{ water_scarcity[1] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[1]"
                      (blur)="finishEditingWaterScarcity(1, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[1]"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[2]"
                      (click)="startEditingWaterScarcity(2)"
                    >
                      {{ water_scarcity[2] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[2]"
                      (blur)="finishEditingWaterScarcity(2, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[2]"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[3]"
                      (click)="startEditingWaterScarcity(3)"
                    >
                      {{ water_scarcity[3] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[3]"
                      (blur)="finishEditingWaterScarcity(3, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[3]"
                    ></textarea>
                  </td>
                </tr>
              </table>
              </div>

              <div class="ccs-sub-div2">
              <table class="ccs-table" style="margin-bottom: 25px">
                <tr>
                  <th>
                    <div
                      *ngIf="!editing_water_scarcity[4]"
                      (click)="startEditingWaterScarcity(4)"
                    >
                      {{ water_scarcity[4] }}
                    </div>

                    <textarea
                      rows="2" cols="117"
                      style="font-size: 1.3rem; font-weight: 600"
                      *ngIf="editing_water_scarcity[4]"
                      (blur)="finishEditingWaterScarcity(4, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[4]"
                    ></textarea>
                  </th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <div
                          *ngIf="!editing_water_scarcity[5]"
                          (click)="startEditingWaterScarcity(5)"
                        >
                          {{ water_scarcity[5] }}
                        </div>

                        <textarea
                          rows="3" cols="125"
                          style="font-size: 1.3rem"
                          *ngIf="editing_water_scarcity[5]"
                          (blur)="finishEditingWaterScarcity(5, $event)"
                          class="resizable-input"
                          (ngModelChange)="doesChange()"
                          [(ngModel)]="current_issue_ph[0].water_scarcity[5]"
                        ></textarea>
                      </li>
                      <li>
                        <div
                          *ngIf="!editing_water_scarcity[6]"
                          (click)="startEditingWaterScarcity(6)"
                        >
                          {{ water_scarcity[6] }}
                        </div>

                        <textarea
                          rows="3" cols="125"
                          style="font-size: 1.3rem"
                          *ngIf="editing_water_scarcity[6]"
                          (blur)="finishEditingWaterScarcity(6, $event)"
                          class="resizable-input"
                          (ngModelChange)="doesChange()"
                          [(ngModel)]="current_issue_ph[0].water_scarcity[6]"
                        ></textarea>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr></tr>
              </table>
            </div>

            <div class="ccs-sub-div2">
              <table class="ccs-table" style="margin-bottom: 25px">
                <tr>
                  <th>
                    <div
                      *ngIf="!editing_water_scarcity[7]"
                      (click)="startEditingWaterScarcity(7)"
                    >
                      {{ water_scarcity[7] }}
                    </div>

                    <textarea
                      rows="2" cols="117"
                      style="font-size: 1.3rem; font-weight: 600"
                      *ngIf="editing_water_scarcity[7]"
                      (blur)="finishEditingWaterScarcity(7, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[7]"
                    ></textarea>
                  </th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      <li>
                        <div
                          *ngIf="!editing_water_scarcity[8]"
                          (click)="startEditingWaterScarcity(8)"
                        >
                          {{ water_scarcity[8] }}
                        </div>

                        <textarea
                          rows="3" cols="125"
                          style="font-size: 1.3rem"
                          *ngIf="editing_water_scarcity[8]"
                          (blur)="finishEditingWaterScarcity(8, $event)"
                          class="resizable-input"
                          (ngModelChange)="doesChange()"
                          [(ngModel)]="current_issue_ph[0].water_scarcity[8]"
                        ></textarea>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr></tr>
              </table>
            </div>

            <div class="ccs-sub-div2">
              <table class="ccs-table" style="margin-bottom: 30px">
                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[9]"
                      (click)="startEditingWaterScarcity(9)"
                    >
                      {{ water_scarcity[9] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[9]"
                      (blur)="finishEditingWaterScarcity(9, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[9]"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[10]"
                      (click)="startEditingWaterScarcity(10)"
                    >
                      {{ water_scarcity[10] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[10]"
                      (blur)="finishEditingWaterScarcity(10, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[10]"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div
                      *ngIf="!editing_water_scarcity[11]"
                      (click)="startEditingWaterScarcity(11)"
                    >
                      {{ water_scarcity[11] }}
                    </div>

                    <textarea
                      rows="3" cols="128"
                      style="font-size: 1.3rem"
                      *ngIf="editing_water_scarcity[11]"
                      (blur)="finishEditingWaterScarcity(11, $event)"
                      class="resizable-input"
                      (ngModelChange)="doesChange()"
                      [(ngModel)]="current_issue_ph[0].water_scarcity[11]"
                    ></textarea>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          </tab>

          <tab heading="References" style="margin: 30px 0 0 0;">
            <div
              *ngIf="!editing_references[0]"
              (click)="startEditingReferences(0)"
            >
              {{ references[0] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[0]"
              (blur)="finishEditingReferences(0, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[0]"
            ></textarea>
            
            <br />

            <div
              *ngIf="!editing_references[1]"
              (click)="startEditingReferences(1)"
            >
              {{ references[1] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[1]"
              (blur)="finishEditingReferences(1, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[1]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[2]"
              (click)="startEditingReferences(2)"
            >
              {{ references[2] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[2]"
              (blur)="finishEditingReferences(2, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[2]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[3]"
              (click)="startEditingReferences(3)"
            >
              {{ references[3] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[3]"
              (blur)="finishEditingReferences(3, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[3]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[4]"
              (click)="startEditingReferences(4)"
            >
              {{ references[4] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[4]"
              (blur)="finishEditingReferences(4, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[4]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[5]"
              (click)="startEditingReferences(5)"
            >
              {{ references[5] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[5]"
              (blur)="finishEditingReferences(5, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[5]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[6]"
              (click)="startEditingReferences(6)"
            >
              {{ references[6] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[6]"
              (blur)="finishEditingReferences(6, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[6]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[7]"
              (click)="startEditingReferences(7)"
            >
              {{ references[7] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[7]"
              (blur)="finishEditingReferences(7, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[7]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[8]"
              (click)="startEditingReferences(8)"
            >
              {{ references[8] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[8]"
              (blur)="finishEditingReferences(8, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[8]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[9]"
              (click)="startEditingReferences(9)"
            >
              {{ references[9] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[9]"
              (blur)="finishEditingReferences(9, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[9]"
            ></textarea>

            <br />

            <div
              *ngIf="!editing_references[10]"
              (click)="startEditingReferences(10)"
            >
              {{ references[10] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[10]"
              (blur)="finishEditingReferences(10, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[10]"
            ></textarea>
            
            <br />

            <div
              *ngIf="!editing_references[11]"
              (click)="startEditingReferences(11)"
            >
              {{ references[11] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[11]"
              (blur)="finishEditingReferences(11, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[11]"
            ></textarea> 
            <br />

            <div
              *ngIf="!editing_references[12]"
              (click)="startEditingReferences(12)"
            >
              {{ references[12] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[12]"
              (blur)="finishEditingReferences(12, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[12]"
            ></textarea>
            
            <br />

            <div
              *ngIf="!editing_references[13]"
              (click)="startEditingReferences(13)"
            >
              {{ references[13] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[13]"
              (blur)="finishEditingReferences(13, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[13]"
            ></textarea>
            
            <br />

            <div
              *ngIf="!editing_references[14]"
              (click)="startEditingReferences(14)"
            >
              {{ references[14] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[14]"
              (blur)="finishEditingReferences(14, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[14]"
            ></textarea>
            <br />

            <div
              *ngIf="!editing_references[15]"
              (click)="startEditingReferences(15)"
            >
              {{ references[15] }}
            </div>

            <textarea
              rows="1" cols="189"
              *ngIf="editing_references[15]"
              (blur)="finishEditingReferences(15, $event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="current_issue_ph[0].references[15]"
            ></textarea>

          </tab>
        </tabset>
      </div>
    </div>
  </section>
  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</body>
