<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Explore the pressing problems of climate change on our planet. Discover the challenges, impacts, and urgent issues 
  affecting our environment and future. Join us in understanding and addressing these critical climate concerns">
  <title>Problems</title>
</head>
  <main>
    <div class="content">
      <div class="textbox" *ngFor="let item of cases">
        <h2>{{item.header}}</h2>
        <p>
          {{item.header_description}}
        </p>
      </div>
      <img src="/assets/casesimages/image_cases.webp" alt="sad earth"/>
    </div>
  
    <div *ngFor="let item of cases">
      <h1 class="item-title">{{item.title}}</h1>
    </div>
  
    <div class="card-container" data-aos="zoom-in" *ngFor="let item of cases">
      <div class="card" style="border-color: #9AC5D7">
        <div class="card-content">
          <h2 style="color: #9AC5D7">
            <i class='bx bxs-book-content' style="font-size: 40px;"></i> {{item.cases.case1[0]}}
            <br />
            <br />
            <p style="
                    font-size: 20px;
                    font-weight: 100;
                    color: #000;
                  ">
              {{item.cases.case1[1]}}
            </p>
            <br />
            <div style="
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 70px;
                  ">
              <br /><a style="text-decoration: none; color: #9AC5D7"
                href={{item.cases.case1[3]}}>{{item.cases.case1[2]}} </a><i class='bx bxs-right-arrow' style="font-size: 16px;"></i>
            </div>
          </h2>
        </div>
      </div>
  
      <div class="card" style="border-color: #BF8E0C" *ngFor="let item of cases">
        <div class="card-content">
          <h2 style="color: #BF8E0C">
            <i class='bx bx-target-lock' style="font-size: 45px;"></i> {{item.cases.case2[0]}}
            <br />
            <br />
            <p style="font-size: 20px; font-weight: 100; color: #000">
              {{item.cases.case2[1]}}
            </p>
            <br />
            <div style="
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 70px;
                  ">
              <br /><a style="text-decoration: none; color: #BF8E0C"
                href={{item.cases.case2[3]}}>{{item.cases.case2[2]}} </a><i class='bx bxs-right-arrow' style="font-size: 16px;"></i>
            </div>
          </h2>
        </div>
      </div>
  
      <div class="card" style="border-color: #A2C3F9;" *ngFor="let item of cases">
        <div class="card-content">
          <h2 style="color: #A2C3F9">
            <i class='bx bxs-info-circle' style="font-size: 45px;"></i> {{item.cases.case3[0]}}
            <br />
            <br />
            <p style="font-size: 20px; font-weight: 100; color: #000">
              {{item.cases.case3[1]}}
            </p>
            <br />
            <div style="
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 70px;
                  ">
              <br /><a style="text-decoration: none; color: #A2C3F9"
                href={{item.cases.case3[3]}}>{{item.cases.case3[2]}} </a><i class='bx bxs-right-arrow' style="font-size: 16px;"></i>
            </div>
          </h2>
        </div>
      </div>
  
      <div class="card" style="border-color: #501118" *ngFor="let item of cases">
        <div class="card-content">
          <h2 style="color: #501118">
            <i class='bx bxs-notepad' style="font-size: 45px;"></i> {{item.cases.case4[0]}}
            <br />
            <br />
            <p style="
                    font-size: 20px;
                    font-weight: 100;
                    color: #000;
                  ">
              {{item.cases.case4[1]}}
            </p>
            <br />
            <div style="
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 70px;
                  ">
              <br /><a style="text-decoration: none; color: #501118"
                href={{item.cases.case4[3]}}>{{item.cases.case4[2]}} </a><i class='bx bxs-right-arrow' style="font-size: 16px;"></i>
            </div>
          </h2>
        </div>
      </div>
    </div>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</html>