<main>
        <app-admin-panel></app-admin-panel>
        <div class="content">
          <div class="textbox" *ngFor="let item of problem_trash">
            <div *ngIf="!editing_header" (click)="startEditingHeader()">
              <h2>{{ header }}</h2>
            </div>
            <h3>
              <textarea
                rows="2" cols="25"
                style="color: #021CD3; font-size: 3rem; font-weight: 600"
                *ngIf="editing_header"
                (blur)="finishEditingHeader($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="problem_trash[0].header"
              ></textarea>
            </h3>
            
            <div *ngIf="!editing_header_desc"
              (click)="startEditingHeaderDescription()" > 
              <p>{{ header_desc }}</p>
            </div>
            <p>
              <textarea
                rows="2" cols="54"
                *ngIf="editing_header_desc"
                (blur)="finishEditingHeaderDescription($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="problem_trash[0].header_description"
              ></textarea>
            </p>
          </div>
          <div>
            <img
            src="/assets/casesimages/image_case1.webp"
            alt="sad earth"
          />
          <div class="file-upload">
            
            <h6>Format should be .webp</h6>
            <h6>Max image size is 100kb</h6>
            <h6>Resolution should be exact 1200px*1200px</h6>
            <form (submit)="onImageCase1Selected($event)" enctype="multipart/form-data">
              <input type="file" (change)="imageCase1Selected($event)" #image1Selected>
              <button type="submit">Upload</button>
            </form>
          </div>
          </div>
          
        </div>
        
        <div class="text" *ngFor="let item of problem_trash">
          <h1 style="padding-top: 50px; color: #001DD3">
            {{ item.cases.case1[0] }}
          </h1>
        </div>
        <section>
          <div class="text">
            
            <div
              *ngIf="!editing_paragraphs[0]"
              (click)="startEditingParagraph1()"
            >
              <p>{{ paragraphs[0] }}</p>
            </div>
      
            <textarea
              rows="2" cols="130"
              style="font-size: 1.3rem"
              *ngIf="editing_paragraphs[0]"
              (blur)="finishEditingparagraph1($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="problem_trash[0].paragraphs[0]"
            ></textarea>
            
            <br>

            <div
              *ngIf="!editing_paragraphs[1]"
              (click)="startEditingParagraph2()"
            >
              <p>{{ paragraphs[1] }}</p>
            </div>
      
            <textarea
              rows="2" cols="130"
              style="font-size: 1.3rem"
              *ngIf="editing_paragraphs[1]"
              (blur)="finishEditingparagraph2($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="problem_trash[0].paragraphs[1]"
            ></textarea>
            
            <br>

            <div
              *ngIf="!editing_paragraphs[2]"
              (click)="startEditingParagraph3()"
            >
              <p>{{ paragraphs[2] }}</p>
            </div>
      
            <textarea
              rows="2" cols="130"
              style="font-size: 1.3rem"
              *ngIf="editing_paragraphs[2]"
              (blur)="finishEditingparagraph3($event)"
              class="resizable-input"
              (ngModelChange)="doesChange()"
              [(ngModel)]="problem_trash[0].paragraphs[2]"
            ></textarea>
          </div>
        </section>
        <section>
          <div class="reference-container" *ngFor="let item of problem_trash" style="margin: 50px;">
            <accordion [isAnimated]="true">
              <accordion-group heading="References">
                <div *ngIf="!editing_reference[0]" (click)="startEditingRef1()">
                  <p>{{ references[0] }}</p>
                </div>
      
                <textarea
                  rows="1" cols="189"
                  *ngIf="editing_reference[0]"
                  (blur)="finishEditingRef1($event)"
                  class="resizable-input"
                  (ngModelChange)="doesChange()"
                  [(ngModel)]="problem_trash[0].references[0]"
                ></textarea>
    
              </accordion-group>
            </accordion>
          </div>
        </section>
      
        <div class="publish-container">
          <button type="button" class="publish" (click)="updateData()"> Publish</button>
        </div>
      </main>
      <footer>
      </footer>
      