<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Take action against climate change today. Join our mission for a sustainable future. 
  Explore solutions, get involved, and make a difference. Together, we can combat climate change.">
  <title>Landing Page</title>
</head>
  <section class="parallax">
    <h2 id="text">Protect the Earth,<br />Secure Our Future</h2>
    <img src="assets/landingpagenew/parallaxtop/mountain1.webp" alt="Mountain 1" id="m1" style="height: 109%;"/>
    <img src="assets/landingpagenew/parallaxtop/trees2.webp"  alt="Trees 2" id="t2" />
    <img src="assets/landingpagenew/mountain_02.webp" alt="Mountain 2" id="m2" />
    <img src="assets/landingpagenew/parallaxtop/trees1.webp" alt="Trees 1" id="t1" />
    <img src="assets/landingpagenew/parallaxtop/man1.webp" alt="Man standing on the mountain" id="man" style="width: 120%;"/>
    <img src="assets/landingpagenew/parallaxtop/grass.webp" alt="Grass" id="plants" style="width: 120%; height: 200%;"/>
  </section>

  <section class="parallax" style="background-color: #2d71a8;">
    <section class="sec">
      <h2 id="h2Element" style="font-size: 34px;">Climate Change</h2>
      <p>
        A website regarding climate change should enlighten visitors, educate them,
        and increase awareness of the problem's causes, effects, and possible
        solutions. It acts as a platform for disseminating accurate, current
        information, encouraging environmental stewardship, and engaging the general
        public, individuals and industry in solving the issues of climate change and
        reducing its consequences.
      </p>
    </section>
    <img src="assets/water/water.webp"  alt="Water" id="water" />
    <img src="assets/water/boy.webp" alt="Boy" id="boy"/>
  </section>
  
  
  <header>
    <div class="parallax-div1">
      <img
        class="parallax-1"
        src="assets/landing-page/CC1.webp"
        alt="Temporary parallax image"
      />
    </div>
  </header>
  <body>
    <section>
      <div class="what-can-you-do">
        <div class="wcyd-sub-div-1">
          <div
            class="wcyd-sub-sub-div-1"
          >
            <p>A</p>
            <p>Sustainable</p>
            <p>Path</p>
            <p>Forward</p>
          </div>
  
          <div class="earth"></div>
        </div>
  
        <div class="container">
          <div class="box">
            <img src="assets/landing-page/fossilfuels.webp" alt="Fossil Fuels' Image" />
            <div class="box-title">Keep fossil fuels in the ground</div>
            <div class="box-description">
              Fossil fuels include coal, oil and gas – and the more that are
              extracted and burned, the worse climate change will get. All
              countries need to move their economies away from fossil fuels as
              soon as possible.
            </div>
          </div>
  
          <div class="box">
            <img src="assets/landing-page/amazon.webp" alt="Amazon's Image" />
            <div class="box-title">Protect forests like the Amazon</div>
            <div class="box-description">
              Forests are crucial in the fight against climate change, and
              protecting them is an important climate solution. Cutting down
              forests on an industrial scale destroys giant trees which could be
              sucking up huge amounts of carbon.
            </div>
          </div>
  
          <div class="box">
            <img src="assets/landing-page/energy.webp" alt="Energy Image" />
            <div class="box-title">Invest in renewable energy</div>
            <div class="box-description">
              Changing our main energy sources to clean and renewable energy is
              the best way to stop using fossil fuels. These include technologies
              like solar, wind, wave, tidal and geothermal power.
            </div>
          </div>
  
          <div class="box">
            <img src="assets/landing-page/transport.webp" alt="Transport Image" />
            <div class="box-title">Switch to sustainable transport</div>
            <div class="box-description">
              Petrol and diesel vehicles, planes and ships use fossil fuels.
              Reducing car use, switching to electric vehicles and minimising
              plane travel will not only help stop climate change, it will reduce
              air pollution too.
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container2">
      <div class="box2">
          <div class="circle-image">
            <a href="https://www.facebook.com/sagipmanila">
              <img src="assets/logo/Mdr-logo.webp" alt="Circle Image">
            </a>
          </div>
          <a href="https://www.facebook.com/sagipmanila">
            <h2 class="project-text">MDRRMO</h2>
          </a>
      </div>
      <div class="box2">
          <div class="circle-image">
            <a routerLink="/games">
              <img src="assets/background/climate-escape-background.webp" alt="Circle Image">
            </a>
          </div>
          <a routerLink="/games">
            <h2 class="project-text">Play Climate Escape</h2>
          </a>
      </div>
  </div>
      <section class="parallax">
        <img src="assets/landingpagenew/parallaxtop/BACKGROUND.webp" alt="Forest Background" id="sea" style="width: 120%; height: 100%;" />
        <img src="assets/landingpagenew/parallaxtop/FRONT.webp" alt="Old man sitting and catching fish" id="oldman" />
      </section> 

      <section class="parallax">
        <img src="assets/trash/bottle.webp" alt="Bottle" id="bottle" style="width: 35%; height: 35%; margin-left: 60%; padding-bottom: 3%;"/>
        
          <img src="assets/trash/papershred.webp" alt="Paper Shred" id="papershred" style="width: 35%; height: 35%; margin-right: 50%; padding-bottom: 3%;"/>
         
          <h2 id="text2"><b>Eco-Friendly Waste Disposal Practices: A Key Strategy for Climate
            Change Mitigation</b>
            <br>
            Proper waste disposal is crucial for mitigating climate change as it
            reduces the release of harmful greenhouse gases into the environment.
            As an example, not shredding paper and compressing bottles before
            disposal can make recycling and waste management more efficient,
            decreasing the carbon footprint associated with waste processing and
            helping to combat climate change.
          </h2>
          
      </section>
    <app-landing-page-new></app-landing-page-new>
  </body>
  <footer>
    <app-footer></app-footer>
  </footer>  
</html>