<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Explore the science, impacts, and solutions of climate change on our comprehensive overview page. 
  Understand the urgent need for action and how we can address this global challenge together.">
  <title>Overview of Climate Change</title>
</head>
  <header>
    <div class="header-img">
      <img
        class="parallax-img-1"
        src="assets/overview-of-climate-change/waterlilies.png"
        alt="Waterlily shot by Nico Knaack"
      />
    </div>
  </header>
  <body>
    <section>
      <div class="insights-main-container">
        <div class="insights-container-text">
          <h1>Uncovering insights about our planet</h1>
        </div>
        <div class="insights-container">
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #ffbc66">Carbon Dioxide</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  class="rotate-180"
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>420</h2>
              <p>parts per million(current)</p>
            </div>
          </div>
  
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #ff7866">Global Temperature</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  class="rotate-180"
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>1.1</h2>
              <p>°C since preindustrial</p>
            </div>
          </div>
  
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #a4c6ff">Artic Sea Ice Minimum Extent</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>12.6</h2>
              <p>perecent per decade since 1979</p>
            </div>
          </div>
        </div>
  
        <div class="insights-container-2">
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #a285ff">Ice Sheets</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>424</h2>
              <p>billion metric tons per year</p>
            </div>
          </div>
  
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #7cdfff">Sea Level</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  class="rotate-180"
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>4</h2>
              <p>inches since January 1993</p>
            </div>
          </div>
  
          <div class="insights-container-sub">
            <div class="insights-1-base-sub">
              <h1 style="color: #6bbbff">Ocean Warming</h1>
            </div>
            <div class="insights-2-base-sub">
              <div>
                <img
                  class="rotate-180"
                  width="90px"
                  src="assets/overview-of-climate-change/down-arrow-white.png"
                  alt=""
                />
              </div>
  
              <h2>345</h2>
              <p>zettajoules since 1944</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section>
      <div class="container">
        <div class="text">
          <h1>Fracking Tower</h1>
          <p style="font-size: 19px; font-weight: 500;">
            Fracking is a controversial form of drilling that uses high-pressure
            liquid to create cracks in underground shale to extract natural gas
            and petroleum. Carbon emissions from fossils fuels like these have
            been linked to global warming and climate change.
          </p>
          <p>Photograph by Mark Thiessen / National Geographic</p>
        </div>
        <div class="image">
          <img src="assets/overview-of-climate-change/fracking-tower.jpg" alt="Fracking Tower Image">
        </div>
      </div>    
    </section>
  
    <section>
      <div class="what-is-climate-c">
        <h1>What is Climate Change?</h1>
      </div>
    <div class="flex-container2">
      <div class="box2">
        <a href="evidence"><img src="assets/overview-of-climate-change/what-is-climate-change\evidence.webp" 
        alt="An intricate maze of small lakes and waterways define the Yukon Delta at the confluence of Alaska's Yukon and Kuskokwim Rivers with the frigid Bering Sea. Wildlife abounds on the delta and offshore where sheets of sea ice form during the coldest months of the year."></a>
        <a href="evidence" style="color: #000;"><h2><span>Evidence</span><br> How Do We Know Climate Change Is Real?</h2></a>
      </div>
      <div class="box2">
        <a href="cause"><img src="assets/overview-of-climate-change/what-is-climate-change/causes.webp" 
        alt="Factory producing large amount of smoke"></a>
        <a href="cause" style="color: #000;"><h2><span>Causes</span> <br> Why is Climate Change happening?</h2></a>
      
      </div>
      <div class="box2">
        <a href="effect"><img src="assets/overview-of-climate-change/what-is-climate-change/effects.webp" 
        alt="Polar bear habitat under threat from climate change"></a>
        <a href="effect" style="color: #000;"><h2><span>Effects</span> <br> What Are the Effects of Climate Change?</h2></a>
       
      </div>
      <div class="box2">
        <a href="respondingtoclimatechange"><img src="assets/overview-of-climate-change/what-is-climate-change/solutions.webp" 
        alt="This is a solar power plant in the Waldviertel / Austria."></a>
        <a href="respondingtoclimatechange" style="color: #000;"><h2><span>Solutions</span> <br> What Is Being Done to Solve Climate Change?</h2></a>
        
      </div>
    </div>
  </section>
  
    <section>
      <div class="encyclopedic-entry-container" data-aos="fade-up" data-aos-duration="1200">
        <div class="eec-1">
          <h6 class="all-caps">Encyclopedic Entry</h6>
          <h1>Climate Change Defined</h1>
          <p>
            Climate change is a long-term shift in global or regional climate
            patterns. Often climate change refers specifically to the rise in
            global temperatures from the mid-20th century to present.
          </p>
        </div>
  
        <hr>
        <div class="eec-2">
          <p>
            Climate is sometimes mistaken for weather. But climate is different
            from weather because it is measured over a long period of time,
            whereas weather can change from day to day, or from year to year. The
            climate of an area includes seasonal temperature and rainfall
            averages, and wind patterns. Different places have different climates.
            A desert, for example, is referred to as an arid climate because
            little water falls, as rain or snow, during the year. Other types of
            climate include tropical climates, which are hot and humid, and
            temperate climates, which have warm summers and cooler winters.
          </p>
          <p>
            Climate change is the long-term alteration of temperature and typical
            weather patterns in a place. Climate change could refer to a
            particular location or the planet as a whole. Climate change may cause
            weather patterns to be less predictable. These unexpected weather
            patterns can make it difficult to maintain and grow crops in regions
            that rely on farming because expected temperature and rainfall levels
            can no longer be relied on. Climate change has also been connected
            with other damaging weather events such as more frequent and more
            intense hurricanes, floods, downpours, and winter storms.
          </p>
          <p>
            In polar regions, the warming global temperatures associated with
            climate change have meant ice sheets and glaciers are melting at an
            accelerated rate from season to season. This contributes to sea levels
            rising in different regions of the planet. Together with expanding
            ocean waters due to rising temperatures, the resulting rise in sea
            level has begun to damage coastlines as a result of increased flooding
            and erosion.
          </p>
          <p>
            The cause of current climate change is largely human activity, like
            burning fossil fuels, like natural gas, oil, and coal. Burning these
            materials releases what are called greenhouse gases into Earth’s
            atmosphere. There, these gases trap heat from the sun’s rays inside
            the atmosphere causing Earth’s average temperature to rise. This rise
            in the planet's temperature is called global warming. The warming of
            the planet impacts local and regional climates. Throughout Earth's
            history, climate has continually changed. When occuring naturally,
            this is a slow process that has taken place over hundreds and
            thousands of years. The human influenced climate change that is
            happening now is occuring at a much faster rate.
          </p>
        </div>
      </div>
    </section>
  
    <section>
      <div class="reference-container">
        <accordion [isAnimated]="true">
  
          <accordion-group heading="References">
            <p>National Geographic</p>
            <p>Climate NASA Gov</p>
          </accordion-group>
        </accordion>
      </div>
    </section>
  
    <section class="margins">
  
      <h2>Related News & Features</h2>
      <div class="related-news-container-main">
        <div class="related-news-container" *ngFor="let item of news ">
          <div class="tile-1">
            <div class="rnc-sub-div2">
              <h3><a href="{{item.link}}"  target="_blank">{{item.title}}</a></h3>
              <hr>
              <p>{{item.description}}</p>
            </div>
          </div>
        </div>
      </div>
  
    </section>
  </body>
  <footer>
    <app-footer></app-footer>
  </footer>
</html>