import { Component } from '@angular/core';

@Component({
  selector: 'app-interactivevideo',
  templateUrl: './interactivevideo.component.html',
  styleUrls: ['./interactivevideo.component.css']
})
export class InteractivevideoComponent {

}
