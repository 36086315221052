<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Explore essential climate change materials. Access valuable self-assessment, overview, current-issues, solutions, and climate action SDG 13. 
    Empower yourself with knowledge to make a positive impact on our planet." />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
      rel="stylesheet"
    />
    <title></title>
  </head>
  <body>
    <div class="text">
      <h1 style="font-weight: bolder; font-size: 50px;">Materials</h1>
    </div>

    <div class="card-container">
      <div class="card" style=" background-color: #9AC5D7; border: 3px solid #25232E;">
        <a href="currentIssue">
          <img
          src="assets/materialimages/currentissues.webp"
          alt="Current Issues Image"
          style="cursor: pointer;"
        />
      </a>
        <div class="card-content">
          <a href="currentIssue">
            <h1>Current Issues</h1>
          </a>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="card" style="background-color: #A2C3F9; border: 3px solid #25232E;">
        <a href="problems">
          <img 
          src="assets/materialimages/cases.webp" 
          alt="Cases Image"
          style="cursor: pointer;"
          />
        </a>
        <div class="card-content">
          <a href="problems">
            <h1>Climate Action SDG 13</h1>
          </a>
        </div>
      </div>
    </div>

    <div class="card-container2" style="margin-top: 20px">
      <div class="card" style="background-color: #41F0AA; border: 3px solid #25232E;">
        <a href="solutions">
          <img 
          src="assets/materialimages/solutions.webp" 
          alt="Solutions Image" 
          style="cursor: pointer;"
          />
        </a>
        <div class="card-content">
          <a href="solutions">
            <h1>Solutions</h1>
          </a>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="card" style="background-color: #A6782A; border: 3px solid #25232E;">
        <a href="overviewOfClimateChange">
          <img 
          src="assets/materialimages/overview.webp" 
          alt="Overview of Climate Change Image" 
          style="cursor: pointer;"
          />
        </a>
        <div class="card-content">
          <a href="overviewOfClimateChange">
            <h1>Overview of Climate Change</h1>
          </a>
        </div>
      </div>
    </div>

    <div class="card-container3" style="margin-top: 20px" >
      <div class="card" style="background-color: #501118; border: 3px solid #25232E;">
        <a href="assessment">
          <img 
          src="assets/materialimages/assessment.webp" 
          alt="temporary" 
          style="cursor: pointer;"
          />
        </a>
        <div class="card-content">
          <a href="assessment">
            <h1>Self-Assessment</h1>
          </a>
        </div>
      </div>
    </div>
  </body>
  <app-footer></app-footer>
</html>
