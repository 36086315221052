<body>
  <div class="gameContainer">
    <canvas
      id="unity-canvas"
      width="990"
      height="600"
      style="width: 990px; height: 600px; background: #231f20"
    ></canvas>
  </div>

</body>
<app-footer></app-footer>
