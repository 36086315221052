<body>
    <div class="container">
     <div id="gameContainer"></div>

     <div class="details">
        <h6><b>GAME DETAILS</b></h6>
        <p><img src="assets/game/keys.png"/>
            or
            <img src="assets/game/wasd.png"/>
            Press the arrow keys or WASD to move the player and  <img src="assets/game/space.png" width="20px"/> SPACE button to pick up the trash.</p>
       
        <hr>
        <div class="game-description">
            "Climate Escape" is an arcade game designed to raise awareness about water pollution and climate change. The game encourages players to protect water bodies through its gameplay.
        </div>
       
    </div>
    </div>

    

    <!-- <section class="details">
       
        
    </section> -->
   
</body>
<app-footer></app-footer>
