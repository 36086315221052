<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Privacy Policy</title>
  </head>
  <body>
    <header>
      <h1>Privacy Policy</h1>
      <p>Last modified: September 24, 2023</p>
    </header>

    <div class="container">
      <p>
        Ecotopia (referred to as "we," "us," or "our"). Your privacy is
        important to us, and we are committed to promoting the free exchange of
        knowledge and information while respecting your privacy and personal
        information. This Privacy Policy outlines our practices regarding the
        collection, use, and protection of your personal information. We firmly
        believe that users should not be required
      </p>
      <h2>Information We Collect</h2>
      <p>
        We do not collect personal information from users unless it is
        voluntarily provided for the purpose, like for concern, or question you
        may contact the developers using the contact details provided.
      </p>

      <h2>How We Use Your Information</h2>
      <ul>
        <li>
          Communication: We may use the information we collect if you
          voluntarily provide your email address by sending us emails, we may
          use it to respond to inquiries and request for support.
        </li>
        <li>
          Legal Compliance: To comply with applicable laws, regulations, and
          legal processes.
        </li>
      </ul>

      <h2>Disclosure</h2>
      <p>
        We refrain from selling, leasing, or exchanging your personal details
        with third parties for marketing intentions. Your information may be
        disclosed in the subsequent situations:
      </p>
      <ul>
        <li>
          Legal Requirements: We may disclose your information if required by
          law or in response to valid requests by public authorities (e.g., a
          court or government agency).
        </li>
        <li>
          Protection of Rights: We may disclose your information when we believe
          it is necessary to protect our rights, privacy, safety, or property,
          as well as that of our users.
        </li>
      </ul>

      <h2>Security</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, or alteration. However, no data
        transmission over the internet is completely secure, and we cannot
        guarantee the security of your information.
      </p>

      <h2>Changes to this Policy</h2>
      <p>
        We reserve the right to update this Privacy Policy to reflect changes in
        our practices or legal requirements.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you need clarification or have any concerns regarding this Privacy
        Policy or your personal data, please reach out to us at
        <a href="mailto:ecotopia2023@outlook.com">ecotopia2023@outlook.com</a>.
      </p>

      <p>
        By using Ecotopia services, you indicate your consent to the provisions
        outlined in this Privacy Policy. Your continued use of our services
        indicates your acknowledgment and acceptance of any future revisions to
        this policy.
      </p>
    </div>
  </body>
  <app-footer></app-footer>
</html>
