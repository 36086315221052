<main>
  <app-admin-panel></app-admin-panel>
  <div class="content">
    <div class="textbox" *ngFor="let item of effects_climate_change">
      <div *ngIf="!editing_header" (click)="startEditingHeader()">
        <h2>{{ header }}</h2>
      </div>
      <h3>
        <textarea
          rows="2" cols="25"
          style="color: #021CD3; font-size: 3rem; font-weight: 600"
          *ngIf="editing_header"
          (blur)="finishEditingHeader($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].header"
        ></textarea>
      </h3>
      
        <div *ngIf="!editing_header_desc"
              (click)="startEditingHeaderDescription()" > 
              <p>{{ header_desc }}</p>
        </div>
      <p>
        <textarea
          rows="2" cols="54"
          *ngIf="editing_header_desc"
          (blur)="finishEditingHeaderDescription($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].header_description"
        ></textarea>
      </p>
    </div>
    <div>
      <img
      src="/assets/casesimages/image_case4.webp"
      alt="sad earth"
    />
    <div class="file-upload">
      
      <h6>Format should be .webp</h6>
      <h6>Max image size is 100kb</h6>
      <h6>Resolution should be exact 1200px*1200px</h6>
      <form (submit)="onImageCase4Selected($event)" enctype="multipart/form-data">
        <input type="file" (change)="imageCase4Selected($event)" #image4Selected>
        <button type="submit">Upload</button>
      </form>
    </div>
    </div>
    
  </div>

  <div class="text" *ngFor="let item of effects_climate_change">
    <span id="next">
      <h1 style="color: #501118">
        {{ item.cases.case4[0] }}
      </h1>
    </span>

    <ol>
      <li>
        <div
          *ngIf="!editing_case_content[0]"
          (click)="startEditingCaseContent1()"
        >
          <b style="color: #000; font-size: 27px">{{ case4_content[0] }}</b>
        </div>

        <textarea
          style="font-size: 1.5rem; font-weight: bold"
          *ngIf="editing_case_content[0]"
          (blur)="finishEditingCaseContent1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[0]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[1]"
          (click)="startEditingCaseContent2()"
        >
          <p>{{ case4_content[1] }}</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[1]"
          (blur)="finishEditingCaseContent2($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[1]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[2]"
          (click)="startEditingCaseContent3()"
        >
          <p style="color: #501118">{{ case4_content[2] }} (Link)</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[2]"
          (blur)="finishEditingCaseContent3($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[2]"
        ></textarea>
      </li>
      <li>
        <div
          *ngIf="!editing_case_content[3]"
          (click)="startEditingCaseContent4()"
        >
          <b style="color: #000; font-size: 27px">{{ case4_content[3] }}</b>
        </div>

        <textarea
          style="font-size: 1.5rem; font-weight: bold"
          *ngIf="editing_case_content[3]"
          (blur)="finishEditingCaseContent4($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[3]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[4]"
          (click)="startEditingCaseContent5()"
        >
          <p>{{ case4_content[4] }}</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[4]"
          (blur)="finishEditingCaseContent5($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[4]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[5]"
          (click)="startEditingCaseContent6()"
        >
          <p style="color: #501118">{{ case4_content[5] }} (Link)</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[5]"
          (blur)="finishEditingCaseContent6($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[5]"
        ></textarea>
      </li>
      <li>
        <div
          *ngIf="!editing_case_content[6]"
          (click)="startEditingCaseContent7()"
        >
          <b style="color: #000; font-size: 27px">{{ case4_content[6] }}</b>
        </div>

        <textarea
          style="font-size: 1.5rem; font-weight: bold"
          *ngIf="editing_case_content[6]"
          (blur)="finishEditingCaseContent7($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[6]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[7]"
          (click)="startEditingCaseContent8()"
        >
          <p>{{ case4_content[7] }}</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[7]"
          (blur)="finishEditingCaseContent8($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[7]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[8]"
          (click)="startEditingCaseContent9()"
        >
          <p style="color: #501118">{{ case4_content[8] }} (Link)</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[8]"
          (blur)="finishEditingCaseContent9($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[8]"
        ></textarea>
      </li>
      <li>
        <div
          *ngIf="!editing_case_content[9]"
          (click)="startEditingCaseContent10()"
        >
          <b style="color: #000; font-size: 27px">{{ case4_content[9] }}</b>
        </div>

        <textarea
          style="font-size: 1.5rem; font-weight: bold"
          *ngIf="editing_case_content[9]"
          (blur)="finishEditingCaseContent10($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[9]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[10]"
          (click)="startEditingCaseContent11()"
        >
          <p>{{ case4_content[10] }}</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[10]"
          (blur)="finishEditingCaseContent11($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[10]"
        ></textarea>

        <div
          *ngIf="!editing_case_content[11]"
          (click)="startEditingCaseContent12()"
        >
          <p style="color: #501118">{{ case4_content[11] }} (Link)</p>
        </div>

        <textarea
          style="font-size: 1.3rem"
          *ngIf="editing_case_content[11]"
          (blur)="finishEditingCaseContent12($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="effects_climate_change[0].case4_content[11]"
        ></textarea>
      </li>
    </ol>
  </div>
  <section>
    <div
      class="reference-container"
      *ngFor="let item of effects_climate_change"
      style="margin: 50px"
      ;
    >
      <accordion [isAnimated]="true">
        <accordion-group heading="References">
          <div *ngIf="!editing_reference[0]" (click)="startEditingRef1()">
            <p>{{ references[0] }}</p>
          </div>

          <textarea
            rows="1"
            cols="189"
            *ngIf="editing_reference[0]"
            (blur)="finishEditingRef1($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="effects_climate_change[0].references[0]"
          ></textarea>
        </accordion-group>
      </accordion>
    </div>
  </section>
  <section>
    <div
      class="reference-container"
      *ngFor="let item of effects_climate_change"
      style="margin: 50px"
    >
      <accordion [isAnimated]="true">
        <accordion-group heading="References">
          <div *ngIf="!editing_reference[0]" (click)="startEditingRef1()">
            <p>{{ references[0] }}</p>
          </div>

          <textarea
            rows="1"
            cols="189"
            *ngIf="editing_reference[0]"
            (blur)="finishEditingRef1($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="effects_climate_change[0].references[0]"
          ></textarea>
        </accordion-group>
      </accordion>
    </div>
  </section>
  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</main>
