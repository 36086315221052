<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Discover our commitment to combatting climate change. 
    Learn about our passionate team dedicated to raising awareness, fostering sustainability, 
    and driving positive environmental change. Join us in the fight against climate crisis." />
    <title>Ecotopia: MAAfia's Team</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Jost&display=swap"
      rel="stylesheet"
    />
  </head>
  <body>
    <main>
      <div class="parent">
        <div class="sub-left">

          <div class="about-maafia">
            <h1>About Maafia</h1>
          </div>

          <div class="sub-objective2" style="padding-bottom: 20px;">
            <div class="objective-1">
              <img src="assets/logo/logo.webp" alt="Ecotopia Logo" style="width: 180px">
            </div>
            <div class="email">
              <p>ecotopia2023@outlook.com</p>
              <p>551 M.F Jhocson St. Sampaloc, Manila, PH 1008</p>
            </div>
          </div>

          <div class="objectives">
            <h2 style="font-weight: bold;">Objectives</h2>
            <div class="sub-objective">
              <div class="objective-1">
                <figure>
                  <img src="assets/teamimages/objective/objective1.webp" alt="Objective 1 Image">
                  <figcaption>Interactive, and Immersive Multimedia Elements</figcaption>
                </figure>
              </div>
              <div class="objective-1">
                <figure>
                  <img src="assets/teamimages/objective/objective2.webp" alt="Objective 2 Image">
                  <figcaption>Assessment Module</figcaption>
                </figure>
              </div>
              <div class="objective-1">
                <figure>
                  <img src="assets/teamimages/objective/objective3.webp" alt="Objective 3 Image">
                  <figcaption>Admin Module</figcaption>
                </figure>
              </div>
              <div class="objective-1">
                <figure>
                  <img src="assets/teamimages/objective/objective4.webp" alt="Objective 4 Image">
                  <figcaption>Mini Arcade Game Module</figcaption>
                </figure>
              </div>
              <div class="objective-1">
                <figure>
                  <img src="assets/teamimages/objective/objective5.webp" alt="Objective 4 Image">
                  <figcaption>Funtionality, Usability, Reliability, Performance, and Supportability (FURPS) Model</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div class="partners" role="region" aria-label="Partners Section">
            <div>
              <h2 style="font-weight: bold;">Partners</h2>
            </div>
            <div class="partners-logos">
              <div class="Mdrrmo">
                <img src="assets/logo/Mdr-logo.webp" alt="MDRRMO's Logo" width="150px">
              </div>
            </div>
          </div>
        </div>

        <div class="sub-right">
          <div class="the-team">
            <div class="texts">
              <div class="the-team-name">
                <h1 style="font-weight: bold; color: #141955;">Maafia</h1>
              </div>
              <div class="the-team-description">
                <p>
                  Welcome to Maafia, a dedicated and talented team of individuals
                  enrolled in the BSIT-MAA. Our team consists of four passionate members
                  who have come together to develop a Parallax Website with a
                  captivating mini-arcade game centered around the critical issue of
                  climate change.
                  At Maafia, our primary goal is to deliver quality in
                  everything we create. Led by Karl Marx Roxas, a visionary team leader,
                  we ensure that our project maintains high standards of exellence. His
                  leadership skills drive our team's productivity and overall success.
                 
                  Together, as the Maafia group, we are committed to making a
                  positive impact by raising awareness about climate change through our
                  Parallax website and mini-arcade game. We strive to provide an
                  exceptional user experience while educating and motivating users to
                  take action towards a more sustainable future. 
                  <br>
                  <br>
                  Join us on this journeys we combine our skills, knowledge, and passion to make a difference
                  in the world. Together, we can create a better tomorrow.
                </p>
              </div>
            </div>
      
            <div class="profiles">
                <section class="about">
                    <div class="content">
                      <div class="content-left color4">
                        <img src="assets/teamimages/richard.jpg" alt="Sir Richard Picture" />
                      </div>
                      <div class="content-right color4">
                        <article>
                        <h1 style="font-weight: bold;">Ryan Richard Guadana</h1>
                        <h5>College Research Director and Maafia Advisor</h5>
                        <br>
                        <p>
                          A beacon of knowledge and wisdom, holds the esteemed position of
                          Research Director at National University Manila College of Computing and Information Technology.
                          With an unwavering commitment to academic exellence, Richard Guadana guides aspiring researchers
                          on their journey to discovery.
                        </p>
                      </article>
                      </div>
                    </div>
                  </section>

              <section class="about">
                <div class="content">
                  <div class="content-left color1">
                    <img src="assets/teamimages/karl.webp" alt="Karl's image" />
                  </div>
                  <div class="content-right color1">
                    <article>
                    <h1 style="font-weight: bold;">Karl Marx Roxas</h1>
                    <h5>Team Lead / Full Stack Developer</h5>
                    <br>
                    <p>
                      I'm a fourth-year college student at National University - Manila,
                      currently pursuing a degree in BSIT-MAA. Although my course is
                      specializing into graphics, I'm determined to become a developer.
                      I love learning about different technologies, and I can
                      confidently say that I can handle pretty much anything if given
                      enough time. Math, networking, algorithms, hardware and philosophy
                      also interest me, so I spend a lot of time reading and watching
                      computer-related stuff. I believe in forming good habits every
                      day, so that when opportunities comes my way, I'm ready to seize
                      them. As Epictetus said, "If you want to be beautiful, you have to
                      make beautiful choices."
                    </p>
                  </article>
                  </div>
                </div>
              </section>

              <section class="about">
                <div class="content">
                  <div class="content-left color2">
                    <img src="assets/teamimages/Vince.webp" alt="Vincent's image" />
                  </div>
                  <div class="content-right color2">
                    <article>
                    <h1 style="font-weight: bold;">Vincent Regala</h1>
                    <h5>Frontend Developer</h5>
                    <br>
                    <p>
                      I'm a fourth-year college student at National University - Manila,
                      currently pursuing a degree in BSIT-MAA. I am a Frontend
                      developer with a passion for creating user-friendly and responsive
                      websites. I have learned various web technologies and frameworks
                      through my projects in college, where I designed and developed a
                      website for a local business. I enjoy working with HTML, CSS,
                      JavaScript, Bootstrap, and Angular to create modern and dynamic
                      web pages that meet the needs and expectations of the clients and
                      users.
                    </p>
                  </article>
                  </div>
                </div>
              </section>
              
              <section class="about">
                <div class="content">
                  <div class="content-left color3">
                    <img src="assets/teamimages/karina.webp" alt="Karina's image" />
                  </div>
                  <div class="content-right color3">
                    <article>
                    <h1 style="font-weight: bold;">Karina Dela Cruz</h1>
                    <h5>Documenter & Researcher / Assistant Artist</h5>
                    <br>
                    <p>
                      I'm a fourth-year student at National University of Manila pursuing
                      BSIT-MAA. I aspire to be a designer and an artist. I'm currently
                      developing my artistic style through improving my performing arts
                      abilities, and yet I currently appreciate drawing line arts and
                      designing layouts for my personal web portfolio. I firmly believe
                      in the phrase "malayo PA, pero malayo NA," which refers that I'm
                      still far from my dreams, but I'm far enough to be grateful. This
                      is despite the fact that I may have discovered my skills somewhat
                      later in life, I'm still excited to make the leap and refine my
                      skill with the people I'm working with.
                    </p>
                    </article>
                  </div>
                </div>
              </section>
              
              <section class="about">
                <div class="content">
                  <div class="content-left color4">
                    <img src="assets/teamimages/maura.webp" alt="Maura's image" />
                  </div>
                  <div class="content-right color4">
                    <article>
                    <h1 style="font-weight: bold;">Maura San Roque</h1>
                    <h5>Lead Artist</h5>
                    <br>
                    <p>
                      I am a fourth-year IT student at National University and a
                      freelance character artist. I spent most of my years learning to
                      draw, and I am admittedly still a learner. I have experience in
                      cartooning, character illustration, character design and
                      watercolor painting. I create fan-art for games, popular anime
                      shows and series and create original characters outside of
                      fan-content. I aim to expand my experience to background
                      illustration, comics and graphic design.
                    </p>
                  </article>
                  </div>
                </div>
              </section>
            </div>
            
          </div>
        </div> 
      </div>
    </main>
    <footer>
      <app-footer></app-footer>
    </footer>
  </body>
</html>
