<app-admin-panel></app-admin-panel>
<body>
  <div class="background" style="margin-bottom: 50px">
    <img
      src="assets/solutionsimages/action.webp"
      alt="Act now! image"
      width="100%"
    />

    <div class="file-upload">
      
      <h6>Format should be .webp</h6>
      <h6>Max image size is 100kb</h6>
      <h6>Resolution should be not exceed 1666px*1111px</h6>
      <form (submit)="onSolution1Selected($event)"enctype="multipart/form-data">
        <input type="file" (change)="imageSelected($event)" #imageSolution1Selected>
        <button type="submit">Upload</button>
      </form>
    </div>
  </div>

  <!-- TODO: Continue updating upload picture here -->
  <div class="background" style="margin-bottom: 50px">
    <!-- <img
      src="assets/solutionsimages/action.webp"
      alt="Act now! image"
      width="100%"
    /> -->
  </div>

  <main style="padding: 0 30px 0 30px">
    <div class="text">
      <div>
      <!-- <div *ngIf="!editing_header" (click)="startEditingHeader()"> -->
        <h1 style="color: #001DD3">{{ header }}</h1>
      </div>

      <textarea
        rows="1" cols="73"
        style="font-size: 3rem; color: #001DD3; font-weight: 600"
        *ngIf="editing_header"
        (blur)="finishEditingHeader($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].header"
      ></textarea>

      <div
        *ngIf="!editing_header_desc"
        (click)="startEditingHeaderDescription()"
      >
        <h4 style="color: #000">{{ header_desc }}</h4>
      </div>

      <textarea
        rows="3" cols="159"
        style="font-size: 1.4rem; color: #000; font-weight: 500"
        *ngIf="editing_header_desc"
        (blur)="finishEditingHeaderDescription($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].header_description"
      ></textarea>

      <br />

      <!-- Bullet 1 -->
      <div *ngIf="!editing_bullet_1[0]" (click)="startEditingBullet1Title()">
        <b style="font-size: 23px; color: #9AC5D7">{{ bullet1[0] }}</b>
      </div>

      <textarea
        rows="2" cols="159"
        style="font-size: 23px; color: #9AC5D7; font-weight: 500"
        *ngIf="editing_bullet_1[0]"
        (blur)="finishEditingBullet1Title($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_1[0]"
      ></textarea>

      <div
        *ngIf="!editing_bullet_1[1]"
        (click)="startEditingBullet1Description()"
      >
        <p>{{ bullet1[1] }}</p>
      </div>

      <textarea
        rows="4" cols="189"
        style="font-size: 1.3rem"
        *ngIf="editing_bullet_1[1]"
        (blur)="finishEditingBullet1Description($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_1[1]"
      ></textarea>

      <br />
      <br />

      <!-- Bullet 2 -->
      <div *ngIf="!editing_bullet_2[0]" (click)="startEditingBullet2Title()">
        <b style="font-size: 23px; color: #9AC5D7">{{ bullet2[0] }}</b>
      </div>

      <textarea
        rows="2" cols="159"
        style="font-size: 23px; color: #9AC5D7; font-weight: 500"
        *ngIf="editing_bullet_2[0]"
        (blur)="finishEditingBullet2Title($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_2[0]"
      ></textarea>

      <div
        *ngIf="!editing_bullet_2[1]"
        (click)="startEditingBullet2Description()"
      >
        <p>{{ bullet2[1] }}</p>
      </div>

      <textarea
        rows="4" cols="189"
        style="font-size: 1.3rem"
        *ngIf="editing_bullet_2[1]"
        (blur)="finishEditingBullet2Description($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_2[1]"
      ></textarea>

      <br />
      <br />

      <div *ngIf="!editing_bullet_3[0]" (click)="startEditingBullet3Title()">
        <b style="font-size: 23px; color: #9AC5D7">{{ bullet3[0] }}</b>
      </div>

      <textarea
        rows="2" cols="159"
        style="font-size: 23px; color: #9AC5D7; font-weight: 500"
        *ngIf="editing_bullet_3[0]"
        (blur)="finishEditingBullet3Title($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_3[0]"
      ></textarea>

      <div
        *ngIf="!editing_bullet_3[1]"
        (click)="startEditingBullet3Description()"
      >
        <p>{{ bullet3[1] }}</p>
      </div>

      <textarea
        rows="4" cols="189"
        style="font-size: 1.3rem"
        *ngIf="editing_bullet_3[1]"
        (blur)="finishEditingBullet3Description($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="y_should_we_take_action[0].bullets.bullet_3[1]"
      ></textarea>
    </div>

    <div class="flex-container">
      <div class="flex-box">
        <div *ngIf="!editing_descriptions[0]" (click)="startEditingDesc1()">
          <h3 style="font-size: 23px; color: #9AC5D7">{{ descriptions[0] }}</h3>
        </div>

        <textarea
          rows="3" cols="145"
          style="font-size: 23px; color: #9AC5D7; font-weight: 500"
          *ngIf="editing_descriptions[0]"
          (blur)="finishEditingDesc1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="y_should_we_take_action[0].descriptions[0]"
        ></textarea>

        <div *ngIf="!editing_descriptions[1]" (click)="startEditingDesc2()">
          <p>{{ descriptions[1] }}</p>
        </div>

        <textarea
          rows="3" cols="158"
          style="font-size: 1.3rem"
          *ngIf="editing_descriptions[1]"
          (blur)="finishEditingDesc2($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="y_should_we_take_action[0].descriptions[1]"
        ></textarea>

        <br />
        <br />

        <li>
          <div
            *ngIf="!editing_bullet_4[0]"
            (click)="startEditingBullet4Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet4[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_4[0]"
            (blur)="finishEditingBullet4Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_4[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_4[1]"
            (click)="startEditingBullet4Description()"
          >
            <p>{{ bullet4[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_4[1]"
            (blur)="finishEditingBullet4Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_4[1]"
          ></textarea>
        </li>
        <br />
        <li>
          <div
            *ngIf="!editing_bullet_5[0]"
            (click)="startEditingBullet5Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet5[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_5[0]"
            (blur)="finishEditingBullet5Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_5[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_5[1]"
            (click)="startEditingBullet5Description()"
          >
            <p>{{ bullet5[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_5[1]"
            (blur)="finishEditingBullet5Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_5[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_6[0]"
            (click)="startEditingBullet6Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet6[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_6[0]"
            (blur)="finishEditingBullet6Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_6[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_6[1]"
            (click)="startEditingBullet6Description()"
          >
            <p>{{ bullet6[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_6[1]"
            (blur)="finishEditingBullet6Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_6[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_7[0]"
            (click)="startEditingBullet7Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet7[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_7[0]"
            (blur)="finishEditingBullet7Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_7[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_7[1]"
            (click)="startEditingBullet7Description()"
          >
            <p>{{ bullet7[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_7[1]"
            (blur)="finishEditingBullet7Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_7[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_8[0]"
            (click)="startEditingBullet8Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet8[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_8[0]"
            (blur)="finishEditingBullet8Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_8[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_8[1]"
            (click)="startEditingBullet8Description()"
          >
            <p>{{ bullet8[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_8[1]"
            (blur)="finishEditingBullet8Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_8[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_9[0]"
            (click)="startEditingBullet9Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet9[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_9[0]"
            (blur)="finishEditingBullet9Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_9[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_9[1]"
            (click)="startEditingBullet9Description()"
          >
            <p>{{ bullet9[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_9[1]"
            (blur)="finishEditingBullet9Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_9[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_10[0]"
            (click)="startEditingBullet10Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet10[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_10[0]"
            (blur)="finishEditingBullet10Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_10[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_10[1]"
            (click)="startEditingBullet10Description()"
          >
            <p>{{ bullet10[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_10[1]"
            (blur)="finishEditingBullet10Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_10[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_11[0]"
            (click)="startEditingBullet11Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet11[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_11[0]"
            (blur)="finishEditingBullet11Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_11[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_11[1]"
            (click)="startEditingBullet11Description()"
          >
            <p>{{ bullet11[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_11[1]"
            (blur)="finishEditingBullet11Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_11[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_12[0]"
            (click)="startEditingBullet12Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet12[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_12[0]"
            (blur)="finishEditingBullet12Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_12[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_12[1]"
            (click)="startEditingBullet12Description()"
          >
            <p>{{ bullet12[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_12[1]"
            (blur)="finishEditingBullet12Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_12[1]"
          ></textarea>
        </li>

        <br />

        <li>
          <div
            *ngIf="!editing_bullet_13[0]"
            (click)="startEditingBullet13Title()"
          >
            <b style="font-size: 23px; color: #9AC5D7">{{ bullet13[0] }}</b>
          </div>

          <textarea
            rows="2" cols="145"
            style="font-size: 23px; color: #9AC5D7; font-weight: 500"
            *ngIf="editing_bullet_13[0]"
            (blur)="finishEditingBullet13Title($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_13[0]"
          ></textarea>

          <div
            *ngIf="!editing_bullet_13[1]"
            (click)="startEditingBullet13Description()"
          >
            <p>{{ bullet13[1] }}</p>
          </div>

          <textarea
            rows="4" cols="175"
            style="font-size: 1.3rem"
            *ngIf="editing_bullet_13[1]"
            (blur)="finishEditingBullet13Description($event)"
            class="resizable-input"
            (ngModelChange)="doesChange()"
            [(ngModel)]="y_should_we_take_action[0].bullets.bullet_13[1]"
          ></textarea>
        </li>
      </div>

      <section>
        <div class="reference-container">
          <accordion [isAnimated]="true">
            <accordion-group heading="References">
              <div *ngIf="!editing_references[0]" (click)="startEditingRef1()">
                <p>{{ references[0] }}</p>
              </div>

              <textarea
                rows="1" cols="189"
                *ngIf="editing_references[0]"
                (blur)="finishEditingRef1($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="y_should_we_take_action[0].references[0]"
              ></textarea>

              <div *ngIf="!editing_references[1]" (click)="startEditingRef2()">
                <p>{{ references[1] }}</p>
              </div>

              <textarea
                rows="1" cols="189"
                *ngIf="editing_references[1]"
                (blur)="finishEditingRef2($event)"
                class="resizable-input"
                (ngModelChange)="doesChange()"
                [(ngModel)]="y_should_we_take_action[0].references[1]"
              ></textarea>
            </accordion-group>
          </accordion>
        </div>
      </section>
    </div>
  </main>
  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</body>
