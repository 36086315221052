<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="description" content="Explore real-world solutions to climate change through cases. 
  Dive into inspiring examples of environmental action, innovation, and impact. Discover how we're making a difference.">
  <title>Case 4</title>
</head>
<body>
  <main>
    <div class="content" *ngFor="let item of effects_climate_change">
      <div class="textbox">
        <h2>{{item.header}}</h2>
        <p>
          {{item.header_description}}
        </p>
      </div>
      <img src="/assets/casesimages/image_case4.webp" alt="sad earth" >
    </div>
  
    <div *ngFor="let item of effects_climate_change">
      <h1 class="item-title">{{item.title}}</h1>
    </div>
    
    <div class="card-container" data-aos="zoom-in" *ngFor="let item of effects_climate_change">
      <div class="card" style="border-color:#9AC5D7; align-items: center;">
        <div class="card-content">
          <h2><a class="learn-more-button" href={{item.cases.case1[1]}} style="text-decoration: none;color: #9AC5D7;"><i 
            class='bx bxs-book-content' style="font-size: 40px;"></i> {{item.cases.case1[0]}}</a></h2>
        </div>
      </div>
  
      <div class="card" style="border-color:#A6782A; align-items: center;">
        <div class="card-content">
          <h2><a class="learn-more-button" href={{item.cases.case2[1]}} style="text-decoration: none;color: #A6782A;"><i 
            class='bx bx-target-lock' style="font-size: 45px;"></i> {{item.cases.case2[0]}}</a></h2>
        </div>
      </div>
  
  
      <div class="card" style="border-color:#A2C3F9; align-items: center;">
        <div class="card-content">
          <h2><a class="learn-more-button" href={{item.cases.case3[1]}} style="text-decoration: none;color: #A2C3F9;"><i 
            class='bx bxs-info-circle' style="font-size: 45px;"></i> {{item.cases.case3[0]}} </a></h2>
        </div>
      </div>
  
  
      <div class="card" style="border-color:#501118; align-items: center;">
        <div class="card-content">
          <h2><a class="learn-more-button" href={{item.cases.case4[1]}} style="text-decoration: none;color: #501118;"><i
            class='bx bxs-notepad' style="font-size: 45px;"></i> {{item.cases.case4[0]}}</a></h2>
        </div>
      </div>
    </div>
    <hr style="width:100%;">
  
    <div class="text" *ngFor="let item of effects_climate_change">
      <span id="next">
        <h1 style="color: #501118">
          {{item.cases.case4[0]}}
        </h1>
      </span>
  
      <ol>
        <li>
          <b style="color: #000; font-size: 27px">Atmosphere</b>
          <p>{{item.case4_content[1]}}</p>
          <a href={{item.case4_content[2]}} style="text-decoration: none;">
            <p style="color: #501118;">Learn more</p></a>
        </li>
        <li>
          <b style="color: #000; font-size: 27px">{{item.case4_content[3]}}</b>
          <p>{{item.case4_content[4]}}</p>
          <a href={{item.case4_content[5]}} style="text-decoration: none;">
            <p style="color: #501118;">Learn more</p></a>
        </li>
        <li>
          <b style="color: #000; font-size: 27px">{{item.case4_content[6]}}</b>
          <p>{{item.case4_content[7]}}</p>
          <a href={{item.case4_content[8]}} style="text-decoration: none;">
            <p style="color: #501118;">Learn more</p></a>
        </li>
        <li>
          <b style="color: #000; font-size: 27px">{{item.case4_content[9]}}</b>
          <p>{{item.case4_content[10]}}</p>
          <a href={{item.case4_content[11]}} style="text-decoration: none;">
            <p style="color: #501118;">Learn more</p></a>
        </li>
        
      </ol>
    </div>
    <section>
      <div class="reference-container" *ngFor="let item of effects_climate_change">
        <accordion [isAnimated]="true">
          <accordion-group heading="References">
            <a href={{item.references[0]}}
              target="_blank">{{item.references[0]}}</a>
            <br />
            
          </accordion-group>
        </accordion>
      </div>
    </section>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</body>
</html>