<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Effects of Climate Change</title>
  </head>
  <body>
    <h1>Effects of Climate Change</h1>
    <p style="font-size: 20px; font-weight: 200">
      The effects of human-caused global warming are happening now, are
      irreversible for people alive today, and will worsen as long as humans add
      greenhouse gases to the atmosphere.
    </p>

    <hr />

    <h2>Earth Will Continue to Warm and the Effects Will be Profound</h2>

    <!-- <div class="image">
      <img
        src="assets/overview-of-climate-change/effect.jpeg"
        alt="Effects of Global Climate Change"
      />
      <p style="margin-top: 10px">
        The potential future effects of global climate change include more
        frequent wildfires, longer periods of drought in <br>some regions, and an
        increase in the wind intensity and rainfall from tropical cyclones.
        Credit: left - <br>Mike McMillan/USFS, center - Tomas Castelazo / Wikimedia
        Commons / <br> CC BY-SA 4.0, right - NASA.
      </p>
    </div> -->

    <p>
      Global warming is not a future issue. Increased human emissions of
      heat-trapping greenhouse gases are altering the Earth's climate, which is
      already having a significant impact on the environment. Glaciers and ice
      sheets are melting, lake and river ice is breaking up earlier, plant and
      animal ranges are shifting, and plants and trees are blooming earlier.
    </p>

    <p>
      Sea ice loss, rapid sea level rise, and longer, more intense heat waves
      are just a few of the effects of global climate change that scientists
      have long anticipated will happen.
    </p>

    <p>
      Droughts, wildfires, and excessive rainfall are a few examples of changes
      that are occurring more quickly than previously thought by scientists. In
      fact, the Intergovernmental Panel on Climate Change (IPCC), a body of the
      UN tasked with evaluating the science surrounding climate change, asserts
      that the observed changes in our planet's climate are unprecedented in
      human history and that some of these changes will be irreversible over the
      course of the next hundreds to thousands of years.
    </p>

    <p>
      Scientists are very confident that the rise in global temperatures, which
      is mostly caused by greenhouse gases produced by human activity, will last
      for many decades.
    </p>

    <p>
      According to the IPCC's Sixth Assessment report, which was released in
      2021, human emissions of gases that trap heat had already caused the
      climate to warm by almost 2 degrees Fahrenheit (1.1 degrees Celsius)
      between 1850 and 1900. Within the next few decades, the average global
      temperature is predicted to approach or surpass 1.5 degrees C (about 3
      degrees F). All areas of the planet will be impacted by these
      developments.
    </p>

    <p>
      The direction that future human activities take will determine how severe
      the effects of climate change are. More climatic extremes and extensive
      negative repercussions on our planet will result from increased greenhouse
      gas emissions. However, the extent to which we emit carbon dioxide will
      determine these long-term repercussions. Therefore, some of the worst
      effects might be avoided if we can lower emissions.
    </p>

    <h2>Takeaways</h2>
    <ul>
      <li>
        The sea ice loss, glacier and ice sheet melting, sea level rise, and
        more violent heat waves are already occurring, as predicted by
        scientists.
      </li>
      <li>
        Scientists anticipate that greenhouse gases produced by humans will
        continue to cause global temperature increases. The effects of the
        severe weather will also worsen and increase.
      </li>
    </ul>
  </body>
  <section style="margin: 50px">
    <div class="reference-container">
      <accordion [isAnimated]="true">
        <accordion-group heading="References">
          <a href="https://climate.nasa.gov/effects/"
            >https://climate.nasa.gov/effects/</a
          >
        </accordion-group>
      </accordion>
    </div>
  </section>
</html>
