import { Component } from '@angular/core';

@Component({
  selector: 'app-governmentresources',
  templateUrl: './governmentresources.component.html',
  styleUrls: ['./governmentresources.component.css']
})
export class GovernmentresourcesComponent {

}
