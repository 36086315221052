<main>
  <app-admin-panel></app-admin-panel>
  <div class="content">
    <div class="textbox" *ngFor="let item of cases">
      <div *ngIf="!editing_header" (click)="startEditingHeader()">
        <h2>{{ header }}</h2>
      </div>
      <h3>
        <textarea
          rows="2" cols="25"
          style="color: #021CD3; font-size: 3rem; font-weight: 600"
          *ngIf="editing_header"
          (blur)="finishEditingHeader($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].header"
        ></textarea>
      </h3>
      
      <div *ngIf="!editing_header_description"
        (click)="startEditingHeaderDescription()">
        <p>{{ header_desc }}</p>
      </div>
      <p>
        <textarea
          rows="2" cols="54"
          *ngIf="editing_header_description"
          (blur)="finishEditingHeaderDescription($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].header_description"
        ></textarea>
      </p>
    </div>
    <div>
      <img
      src="/assets/casesimages/image_cases.webp"
      alt="sad earth"
    />
    <div class="file-upload">
      
      <h6>Format should be .webp</h6>
      <h6>Max image size is 100kb</h6>
      <h6>Resolution should be exact 1200px*1200px</h6>
      <form (submit)="onImageCasesSelected($event)" enctype="multipart/form-data">
        <input type="file" (change)="ImageCasesSelected($event)" #imageCaseSelected>
        <button type="submit">Upload</button>
      </form>
    </div>
    </div>
    
  </div>

  <div *ngFor="let item of cases">
    <h1
      style="
        color: #0030FF;
        padding-bottom: 30px;
        padding-left: 80px;
        font-size: 50px;
        font-weight: bold;
      "
    >
    <div>
      <!-- <div *ngIf="!editing_header_title" (click)="startEditingTitle()"> -->
        <p>{{ header_title }}</p>
      </div>
      <textarea
        rows="1" cols="73"
        style="color: #021CD3; font-size: 3rem; font-weight: 600"
        *ngIf="editing_header_title"
        (blur)="finishEditingTitle($event)"
        class="resizable-input"
        (ngModelChange)="doesChange()"
        [(ngModel)]="cases[0].title"
      ></textarea>
    </h1>
  </div>

  <div class="card-container" data-aos="zoom-in" *ngFor="let item of cases">
    <div class="card" style="border-color: #9AC5D7">
      <div class="card-content" >
        <div>
        <!-- <div *ngIf="!editing_case1_0" (click)="startEditingCase1_0()"> -->
          <h2 style="color: #9AC5D7">
            <i class='bx bxs-book-content' style="font-size: 40px;"></i>
            {{ case1[0] }}
          </h2>
        </div>
        <textarea
          rows="2" cols="23"
          style="color: #000000; font-size: 2rem; font-weight: 500;"
          *ngIf="editing_case1_0"
          (blur)="finishEditingCase1_0($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case1[0]"
        ></textarea>
        <br />
        <br />

        <div *ngIf="!editing_case1_1" (click)="startEditingCase1_1()">
          <p style="font-size: 20px; font-weight: 100; color: #000">
            {{ case1[1] }}
          </p>
        </div>

        <textarea
        style="  
        position: absolute;
        right: 10px;
        resize: none;"
           rows="15" cols="55"
          *ngIf="editing_case1_1"
          (blur)="finishEditingCase1_1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case1[1]"
        ></textarea>

        <br />
        <div
          style="
            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
            margin-top: 70px;
          "
        ></div>
      </div>
    </div>

    <div class="card" style="border-color: #A6782A" *ngFor="let item of cases">
      <div class="card-content">
        <div>
        <!-- <div *ngIf="!editing_case2_0" (click)="startEditingCase2_0()"> -->
          <h2 style="color: #A6782A">
           <i class='bx bx-target-lock' style="font-size: 45px;"></i>
            {{ case2[0] }}
          </h2>
        </div>

        <textarea
          rows="2" cols="23"
          style="color: #000; font-size: 2rem; font-weight: 500"
          *ngIf="editing_case2_0"
          (blur)="finishEditingCase2_0($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case2[0]"
        ></textarea>

        <br />
        <br />

        <div *ngIf="!editing_case2_1" (click)="startEditingCase2_1()">
          <p style="font-size: 20px; font-weight: 100; color: #000">
            {{ case2[1] }}
          </p>
        </div>

        <textarea
        style="  
        position: absolute;
        right: 10px;
        resize: none;"
           rows="15" cols="55"
          *ngIf="editing_case2_1"
          (blur)="finishEditingCase2_1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case2[1]"
        ></textarea>

        <br />
        <div
          style="text-transform: uppercase; font-size: 20px; font-weight: bold"
        ></div>
      </div>
    </div>

    <div class="card" style="border-color: #A2C3F9" *ngFor="let item of cases">
      <div class="card-content">
        <div>
        <!-- <div *ngIf="!editing_case3_0" (click)="startEditingCase3_0()"> -->
          <h2 style="color: #A2C3F9">
            <i class='bx bxs-info-circle' style="font-size: 45px;"></i>
            {{ case3[0] }}
          </h2>
        </div>

        <textarea
          rows="2" cols="23"
          style="color: #000; font-size: 2rem; font-weight: 500"
          *ngIf="editing_case3_0"
          (blur)="finishEditingCase3_0($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case3[0]"
        ></textarea>
        <br />
        <br />

        <div *ngIf="!editing_case3_1" (click)="startEditingCase3_1()">
          <p style="font-size: 20px; font-weight: 100; color: #000">
            {{ case3[1] }}
          </p>
        </div>

        <textarea
        style="  
        position: absolute;
        right: 10px;
        resize: none;"
           rows="15" cols="55"
          *ngIf="editing_case3_1"
          (blur)="finishEditingCase3_1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case3[1]"
        ></textarea>

        <br />
        <div
          style="
            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
            margin-top: 73px;
          "
        ></div>
      </div>
    </div>

    <div class="card" style="border-color: #501118" *ngFor="let item of cases">
      <div class="card-content">
        <div>
        <!-- <div *ngIf="!editing_case4_0" (click)="startEditingCase4_0()"> -->
          <h2 style="color: #501118">
            <i class='bx bxs-notepad' style="font-size: 45px;"></i>
            {{ case4[0] }}
          </h2>
        </div>

        <textarea
          rows="2" cols="23"
          style="color: #000; font-size: 2rem; font-weight: 500"
          *ngIf="editing_case4_0"
          (blur)="finishEditingCase4_0($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case4[0]"
        ></textarea>

        <br />
        <br />

        <div *ngIf="!editing_case4_1" (click)="startEditingCase4_1()">
          <p style="font-size: 20px; font-weight: 100; color: #000">
            {{ case4[1] }}
          </p>
        </div>

        <textarea
        style="  
        position: absolute;
        right: 10px;
        resize: none;"
           rows="15" cols="55"
          *ngIf="editing_case4_1"
          (blur)="finishEditingCase4_1($event)"
          class="resizable-input"
          (ngModelChange)="doesChange()"
          [(ngModel)]="cases[0].cases.case4[1]"
        ></textarea>

        <br />
        <div
          style="
            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
            margin-top: 75px;
          "
        ></div>
      </div>
    </div>
  </div>

  <div class="publish-container">
    <button type="button" class="publish" (click)="updateData()">
      Publish
    </button>
  </div>
</main>